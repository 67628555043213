import React, { useEffect, useState } from 'react';
import { useHistory} from 'react-router-dom';
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import axios from 'axios';
import ConfigData from "../../config.json";
import iconNuduan from "../../assets/images/icon/img-nuduan.png";
import NuduanMyPoint from '../../components/nuduan/nuduan-my-point';
import { modelEmpGeneral, modelEmpWorkInfo } from '../../components/employee/model_employee';
import { modelNuduanMyPoint } from '../../components/nuduan/model_nuduan';
import NuduanRedeemPoint from '../../components/nuduan/nuduan-redeem-point';
import NuduanRedeemHonor from '../../components/nuduan/nuduan-redeem-honor';

const Nuduans = () => {
    const history = useHistory();
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [dataEmpGeneral, setDataEmpGeneral] = useState(modelEmpGeneral);
    const [dataEmpWorkInfo, setDataEmpWorkInfo] = useState(modelEmpWorkInfo);
    const [dataMyPoint, setDataMyPoint] = useState(modelNuduanMyPoint);
    const [dataMyHistory, setDataMyHistory] = useState([]);
    const [dataItemProduct, setDataItemProduct] = useState([]);
    const [dataItemMoney, setDataItemMoney] = useState([]);
    const [dataItemScholarship, setDataItemScholarship] = useState([]);

    useEffect(() => {
        const employeeId = (sessionStorage.getItem("EmpId-intranet") !== null) ? sessionStorage.getItem("EmpId-intranet") : 0;
        // const employeeId = "63010465";
        LoadDataEmpGeneral(employeeId);
        LoadDataEmpWorkInfo(employeeId);
        LoadDataMyPoint(employeeId);
        LoadDataMyHistory(employeeId);
        LoadDataItemProduct();
        LoadDataItemMoney();
        LoadDataItemScholarship();
    }, []);

    const toggleCustomActiveTab = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const OnClickGoToRedeem = (itemId, itemType) => {
        let paramEncode = btoa(itemId + ";" + itemType);
        history.push("/nuduan/" + paramEncode);
    };

    const LoadDataEmpGeneral = (employeeId) => {
        let url = new URL(ConfigData.API_URL + "employee-general/search");
        url.searchParams.set("status", 1);
        url.searchParams.set("empId", employeeId);
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setDataEmpGeneral(respData.data[0]);
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    const LoadDataEmpWorkInfo = (employeeId) => {
        let url = new URL(ConfigData.API_URL + "employee-work-info/search");
        url.searchParams.set("status", 1);
        url.searchParams.set("empId", employeeId);
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setDataEmpWorkInfo(respData.data[0]);
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    const LoadDataMyPoint = (employeeId) => {
        let url = new URL(ConfigData.API_URL_NUDUAN + "get-my-point.php");
        url.searchParams.set("empId", employeeId);
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setDataMyPoint(respData.data[0]);
            }
        })
        .catch(error => {
            console.log(error);
            // handlerRespError(error, history);
        });
    };

    const LoadDataMyHistory = (employeeId) => {
        let url = new URL(ConfigData.API_URL_NUDUAN + "get-my-redeem.php");
        url.searchParams.set("empId", employeeId);
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                setDataMyHistory(respData.data);
                // let ar = [];
                // ar.push(respData.data[0]);
                // ar.push(respData.data[1]);
                // setDataMyHistory(ar);
            }
        })
        .catch(error => {
            console.log(error);
            // handlerRespError(error, history);
        });
    };

    const LoadDataItemProduct = () => {
        let url = new URL(ConfigData.API_URL_NUDUAN + "get-items.php");
        url.searchParams.set("pointType", 1);
        url.searchParams.set("itemType", 1);
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                setDataItemProduct(respData.data);
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    const LoadDataItemMoney = () => {
        let url = new URL(ConfigData.API_URL_NUDUAN + "get-items.php");
        url.searchParams.set("pointType", 1);
        url.searchParams.set("itemType", 2);
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                setDataItemMoney(respData.data);
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    const LoadDataItemScholarship = () => {
        let url = new URL(ConfigData.API_URL_NUDUAN + "get-items.php");
        url.searchParams.set("pointType", 2);
        url.searchParams.set("itemType", 3);
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                setDataItemScholarship(respData.data);
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <Row className="flex-column-reverse flex-sm-row">
                                <Col sm={12} md={8} lg={8}>
                                    <h1 className="mb-2">Nuduan Program</h1>
                                    <p className="mb-4">The reward for exceptional employees.</p>
                                    <Nav pills className="nav-justified d-block d-md-flex d-lg-flex mb-2">
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={"nav-item-profile " + classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleCustomActiveTab("1");
                                                }}
                                            >
                                                My Point
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={"nav-item-profile " + classnames({
                                                    active: customActiveTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleCustomActiveTab("2");
                                                }}
                                            >
                                                Nuduan Points
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={"nav-item-profile " + classnames({
                                                    active: customActiveTab === "3",
                                                })}
                                                onClick={() => {
                                                    toggleCustomActiveTab("3");
                                                }}
                                            >
                                                Honor Point
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Col>
                                <Col sm={12} md={4} lg={4}>
                                    <div className="text-center text-md-end text-lg-end mb-2 mb-md-0 mb-lg-0">
                                        <img src={iconNuduan} width={280} className="img-fluid" alt="125x125" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <TabContent 
                                        activeTab={customActiveTab} 
                                    >
                                        <TabPane tabId="1">
                                            <NuduanMyPoint dataEmpGeneral={dataEmpGeneral} dataEmpWorkInfo={dataEmpWorkInfo} dataPoint={dataMyPoint} dataHistory={dataMyHistory} />
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <NuduanRedeemPoint itemProducts={dataItemProduct} itemMoneys={dataItemMoney} onClickGoToRedeem={OnClickGoToRedeem} />
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <NuduanRedeemHonor itemScholarships={dataItemScholarship} onClickGoToRedeem={OnClickGoToRedeem} />
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Nuduans;