const ConvertStringDate = (value, oldFormat, newFormat, keyReplace) => {
    let valueReturn = "";
    if(value !== "" && value !== null){
        const splitDate = value.split(keyReplace);
        const splitOldFormat = oldFormat.split(keyReplace);
        let iDay = 0;
        let iMonth = 1;
        let iYear = 2;
        splitOldFormat.forEach(function (value, index) {
            switch(value){
                case "dd" : 
                    iDay = index;
                    break;
                case "MM" : 
                    iMonth = index;
                    break;
                case "yyyy" :
                    iYear = index;
                    break;
            }
        });
        valueReturn = newFormat.replace("yyyy", splitDate[iYear]).replace("MM", splitDate[iMonth]).replace("dd", splitDate[iDay]);
    }
    return valueReturn;
};

export { ConvertStringDate };