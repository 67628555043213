import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { Card, CardBody, Col, Row } from "reactstrap";
import ConfigData from "../../config.json";

const ProfileBusinessCard = ({ dataEmpGeneral }) => {
    const [urlBusinessCard, setUrlBusinessCard] = useState("");

    useEffect(() => {
        let url = ConfigData.BUSINESS_CARD_URL + btoa(dataEmpGeneral.employee_id);
        // console.log(url);
        setUrlBusinessCard(url)
    }, [dataEmpGeneral]);

    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <p className="profile-f-group">Business Card</p>
                            <Row>
                                <Col lg={12} className="text-center mb-4">
                                    <QRCode 
                                        title="BTSG Business Card"
                                        size={256}
                                        value={urlBusinessCard}
                                        bgColor="#FFFFFF"
                                        fgColor="#000000"
                                    />
                                    <p className="profile-f-head mt-2">Scan me :)</p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ProfileBusinessCard;