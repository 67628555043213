const ConvertEmployeeId = (employeeID, digit) => {
    let modifyEmployeeId = '';
    for (let i = 0; i < employeeID.length; i++) {
        modifyEmployeeId += employeeID[i];
        if ((i + 1) % digit === 0 && i !== employeeID.length - 1) {
            modifyEmployeeId += '-';
        }
    }
    return modifyEmployeeId;
};

const ConvertFormatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export { ConvertEmployeeId, ConvertFormatNumber };