import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ConfigData from "../../config.json";
import imgGiftItem from "../../assets/images/icon/img-gift-item.png";

const NuduanRedeemHonor = ({itemScholarships, onClickGoToRedeem}) => {
    const [arrItemScholarship, setArrItemScholarship] = useState(itemScholarships);

    useEffect(() => {
        setArrItemScholarship(itemScholarships);
    }, [itemScholarships]);

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card className="nuduan-redeem">
                        <CardBody>
                            <Row>
                                <Col lg={12}>
                                    <p className="nuduan-redeem-title">Scholarship Reward</p>
                                    <Row>
                                        {(arrItemScholarship.length > 0) ? arrItemScholarship.map((item, key) => (
                                            <Col sm={12} md={3} lg={3} className="nuduan-item-detail" key={"nuduan-scholarship-" + key}>
                                                <Row className="nuduan-scholarship" onClick={(e) => { onClickGoToRedeem(item.id, item.item_type); }}>
                                                    <Col lg={12}>
                                                        {/* {item.item_pic} */}
                                                        <img className="nuduan-scholarship-img" src={(item.item_pic !== "") ? ConfigData.IMAGE_NUDUAN_URL + "list/" + item.item_pic : imgGiftItem} alt="" />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )) : (
                                            <Col lg={12} style={{width: "100%"}} >
                                                <Row className="align-item-center">
                                                    <Col lg={12} className="text-center">
                                                        <h4 className="my-4">No Result Found!</h4>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default NuduanRedeemHonor;