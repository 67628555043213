import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import avatar1 from "../../assets/images/user-dummy-img.jpg";
import ConfigData from "../../config.json";
import LazyLoad from "react-lazyload";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

const GridPhoneBook = ({data, rowPerPage, onClickDetail}) => {
    const [gridData, setGridData] = useState([]);
    const [gridSlice, setGridSlice] = useState([]);
    const [gridRange, setGridRange] = useState([]);
    const [inPage, setInPage] = useState(1);
    const [lightBoxIsOpen, setLightBoxIsOpen] = useState(false);
    const [lightBoxImage, setLightBoxImage] = useState("");

    useEffect(() => {
        setGridData(data);
    }, [data]);

    useEffect(() => {
        const range = calculateRange(gridData, rowPerPage);
        setGridRange([...range]);

        const slice = sliceData(gridData, inPage, rowPerPage);
        setGridSlice([...slice]);
    }, [gridData, setGridRange, inPage, setGridSlice, rowPerPage]);


    const calculateRange = (data, rowsPerPage) => {
        const range = [];
        const num = Math.ceil(data.length / rowsPerPage);
        for(let i = 1; i <= num; i++){
            range.push(i);
        }
        return range;
    };

    const sliceData = (data, page, rowsPerPage) => {
        return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    };

    const onClickShowLightBox = (img) => {
        const objImg = (img !== "") ? ConfigData.IMAGE_URL + img : avatar1;
        setLightBoxImage(objImg);
        setLightBoxIsOpen(true);
    };

    const onClickCloseLightBox = () => {
        setLightBoxIsOpen(false);
    };

    return(
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    <div id="employeeList">
                        <Row className="team-list grid-view-filter">
                            {(gridSlice.length > 0) ? gridSlice.map((item, key) => (
                                <Col key={key}>
                                    <Card className="team-box card-animate card-phonebook">
                                        <CardBody className="p-4">
                                            <Row className="align-items-center team-row">
                                                {/* <Col className="team-settings">
                                                    <Row>
                                                        <Col>
                                                            <div className="flex-shrink-0 me-2">
                                                                <button type="button" className="btn btn-light btn-icon rounded-circle btn-sm favourite-btn" onClick={(e) => favouriteBtn(e.target)}>
                                                                    <i className="ri-star-fill fs-14"></i>
                                                                </button>
                                                            </div>
                                                        </Col>
                                                        <UncontrolledDropdown direction='start' className="col text-end">
                                                            <DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
                                                                <i className="ri-more-fill fs-17"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem className="dropdown-item edit-list" href="#addmemberModal">
                                                                    <i className="ri-pencil-line me-2 align-bottom text-muted"></i>Edit
                                                                </DropdownItem>
                                                                <DropdownItem className="dropdown-item remove-list" href="#removeMemberModal">
                                                                    <i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>Remove
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </Row>
                                                </Col> */}
                                                <Col lg={4} className="col">
                                                    <div className="team-profile-img">
                                                        {/* <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                                            <img src={(item.employee_pic !== "") ? ConfigData.IMAGE_URL + item.employee_pic : avatar1} alt="" className="img-emp-grid d-block rounded-circle" />
                                                        </div> */}
                                                        <LazyLoad>
                                                            <div className="profile-user flex-shrink-0">
                                                                <img src={(item.employee_pic !== "") ? ConfigData.IMAGE_URL + item.employee_pic : avatar1} alt="" onClick={() => onClickShowLightBox(item.employee_pic)}  className="rounded-circle avatar-xl img-thumbnail user-profile-image d-block" />
                                                            </div>
                                                        </LazyLoad>
                                                        <div className="team-content">
                                                            <h5 className="fs-16 mb-1">{item.name_en + " " + item.surname_en + ((item.nickname_en === "") ? "" : " (" + item.nickname_en + ")") }</h5>
                                                            {/* <p className="text-muted mb-1">{(item.name_position === "") ? "-" : item.name_position}</p> */}
                                                            <p className="text-muted mb-1">{(item.name_department === "") ? "-" : item.name_department}</p>
                                                            <p className="mb-1"><i className="ri-mail-line me-2"></i>{(item.office_email === "") ? "-" : item.office_email}</p>
                                                            <p className="mb-1"><i className="mdi mdi-phone-message me-2"></i>{(item.office_phone === "") ? "-" : item.office_phone}</p>
                                                            <p className="mb-1"><i className="mdi mdi-cellphone me-2"></i>{(item.phone_number === "") ? "-" : item.phone_number}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={2} className="col">
                                                    <Link to="#" className="mt-0" onClick={(e) => { onClickDetail(item); }}>View Profile</Link>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )) :<Col style={{width: "100%"}}>
                                    <Card className="team-box">
                                        <CardBody className="p-4">
                                            <Row className="align-items-center team-row">
                                                <Col lg={12} className="col text-center">
                                                    <h4 className="mt-5">No Result Found!</h4>
                                                    <p className="mb-5">Try adjusting your search or filter what you're looking for.</p>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col> 
                            }
                        </Row>
                    </div>
                    {
                        (lightBoxIsOpen !== true) ? "" : <Lightbox image={lightBoxImage} onClose={onClickCloseLightBox} />
                    }
                </Col>
            </Row>
            <div className="justify-content-between d-flex">
                <Link to="#" className="page-link mb-3" onClick={() => { setInPage((inPage - 1 <= 0) ? 1 : inPage - 1 ); }}>Prev</Link>
                <ul className="pagination pagination-separated mb-3">
                    {/* <li className="page-item">
                        <Link to="#" className="page-link" onClick={() => { setInPage(1); }}>First</Link>
                    </li>
                    <li className="page-item">
                        <Link to="#" className="page-link" onClick={() => { setInPage((inPage - 1 <= 0) ? 1 : inPage - 1 ); }}>←</Link>
                    </li> */}
                    {gridRange.map((val, key) => {
                        return (
                            <li key={key} className={"page-item" + (inPage === val ? " active" : "")}>
                                <Link to="#" className="page-link" onClick={() => { setInPage(val); }}>{val}</Link>
                            </li>
                        );
                    })}
                    {/* <li className="page-item">
                        <Link to="#" className="page-link" onClick={() => { setInPage((inPage + 1 >= gridRange.length) ? gridRange.length : inPage + 1 ); }}>→</Link>
                    </li>
                    <li className="page-item">
                        <Link to="#" className="page-link" onClick={() => { setInPage(gridRange.length); }}>Last</Link>
                    </li> */}
                </ul>
                <Link to="#" className="page-link mb-3" onClick={() => { setInPage((inPage + 1 >= gridRange.length) ? gridRange.length : inPage + 1 ); }}>Next</Link>
            </div>
        </React.Fragment>
    );
};

export default GridPhoneBook;



{/* <CardBody className="p-4">
    <Row className="align-items-center team-row">
        <Col className="team-settings">
            <Row>
                <Col>
                    <div className="flex-shrink-0 me-2 float-end">
                        <button type="button" className="btn btn-light btn-icon rounded-circle btn-sm favourite-btn" onClick={(e) => favouriteBtn(item)}>
                            <i className="ri-star-fill fs-14"></i>
                        </button>
                    </div>
                </Col>
            </Row>
        </Col>
        <Col lg={4} className="col">
            <div className="team-profile-img">
                <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                    <img src={avatar1} alt="" className="img-fluid d-block rounded-circle" />
                </div>
                <div className="team-content">
                    <h5 className="fs-16 mb-1">{item.name_th + " " + item.surname_th}</h5>
                    <p className="text-muted mb-1">{(item.name_position === "") ? "-" : item.name_position}</p>
                    <p className="text-muted mb-1">{(item.office_email === "") ? "-" : item.office_email}</p>
                    <p className="text-muted mb-1">{(item.phone_number === "") ? "-" : item.phone_number}</p>
                </div>
            </div>
        </Col>
    </Row>
</CardBody> */}