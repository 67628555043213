import React, { useEffect, useState } from 'react'
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import classnames from "classnames";
import ConfigData from '../../config.json';
import iconProfile from "../../assets/images/icon/img-profile.png"
import ProfileGeneral from '../../components/employee/profile_general';
import ProfileWork from '../../components/employee/profile_work';
import ProfilePersonal from '../../components/employee/profile_personal';
import ProfileSignature from '../../components/employee/profile_signature';
import ProfileBusinessCard from '../../components/employee/profile_business_card';
import { modelEmpGeneral, modelEmpWorkInfo } from '../../components/employee/model_employee';
import axios from 'axios';
import Swal from 'sweetalert2';

function Profile() {
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [dataEmpGeneral, setDataEmpGeneral] = useState(modelEmpGeneral);
    const [dataEmpFamily, setDataEmpFamily] = useState([]);
    const [dataEmpWorkInfo, setDataEmpWorkInfo] = useState(modelEmpWorkInfo);
    const [isEditGeneral, setIsEditGeneral] = useState("");
    

    useEffect(() => {
        const employeeId = (sessionStorage.getItem("EmpId-intranet") !== null) ? sessionStorage.getItem("EmpId-intranet") : 0;
        LoadDataEmpGeneral(employeeId);
        // LoadDataEmpFamily(employeeId);
        LoadDataEmpWorkInfo(employeeId);
    }, []);

    const toggleCustomActiveTab = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const SaveDataEmpGeneral = (values) => {
        setIsEditGeneral("");
        let url = ConfigData.API_URL + "employee-general/" + values.id;
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        let userId = sessionStorage.getItem("uid-intranet");
        let data = [
            {
                "op": "string",
                "path": "nickname_en",
                "value": values.nickname_en
            },
            // {
            //     "op": "date",
            //     "path": "birth_date",
            //     "value": values.birth_date
            // },
            // {
            //     "op": "string",
            //     "path": "shirt_size",
            //     "value": values.shirt_size
            // },
            {
                "op": "string",
                "path": "phone_number",
                "value": values.phone_number
            },
            {
                "op": "string",
                "path": "about_me",
                "value": values.about_me.replace(/\n/g, "<br/>")
            },
            // {
            //     "op": "string",
            //     "path": "skill_me",
            //     "value": values.skill_me.replace(/\n/g, "<br/>")
            // }
        ];
        axios.patch(url, data, { headers: { 
            'Authorization' : token,
            'User': userId,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                LoadDataEmpGeneral(respData.data[0].employee_id);
                setIsEditGeneral("false");
                Swal.fire({
                    icon: 'success',
                    title: 'Data saved.',
                    confirmButtonColor: '#3085d6',
                });
            }
        })
        .catch(error => {
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Failed to save data.',
                confirmButtonColor: '#3085d6',
            });
        });
    };

    const LoadDataEmpGeneral = (employeeId) => {
        let url = new URL(ConfigData.API_URL + "employee-general/search");
        url.searchParams.set("status", 1);
        url.searchParams.set("empId", employeeId);
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setDataEmpGeneral(respData.data[0]);
            }
        })
        .catch(error => {
            console.log(error);
            // handlerRespError(error, history);
        });
    };

    const LoadDataEmpFamily = (employeeId) => {
        let url = new URL(ConfigData.API_URL + "employee-family/");
        url.searchParams.set("status", 1);
        url.searchParams.set("empId", employeeId);
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                setDataEmpFamily(respData.data);
            }
        })
        .catch(error => {
            console.log(error);
            // handlerRespError(error, history);
        });
    };

    const LoadDataEmpWorkInfo = (employeeId) => {
        let url = new URL(ConfigData.API_URL + "employee-work-info/search");
        url.searchParams.set("status", 1);
        url.searchParams.set("empId", employeeId);
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setDataEmpWorkInfo(respData.data[0]);
            }
        })
        .catch(error => {
            console.log(error);
            // handlerRespError(error, history);
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <Row className="flex-column-reverse flex-sm-row">
                                <Col sm={12} md={8} lg={8}>
                                    <h1 className="mb-2">My Profile</h1>
                                    <p className="mb-4">Welcome to your employee profile, the place where you can showcase your professional journey and accomplishments.</p>
                                    <Nav pills className="nav-justified d-block d-md-flex d-lg-flex mb-2">
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer"}}
                                                className={"nav-item-profile " + classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleCustomActiveTab("1");
                                                }}
                                            >
                                                General
                                            </NavLink>
                                        </NavItem>
                                        {/* <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={"nav-item-profile " + classnames({
                                                    active: customActiveTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleCustomActiveTab("2");
                                                }}
                                            >
                                                Personal
                                            </NavLink>
                                        </NavItem> */}
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={"nav-item-profile " + classnames({
                                                    active: customActiveTab === "3",
                                                })}
                                                onClick={() => {
                                                    toggleCustomActiveTab("3");
                                                }}
                                            >
                                                Work
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={"nav-item-profile " + classnames({
                                                    active: customActiveTab === "4",
                                                })}
                                                onClick={() => {
                                                    toggleCustomActiveTab("4");
                                                }}
                                            >
                                                Email signature
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={"nav-item-profile " + classnames({
                                                    active: customActiveTab === "5",
                                                })}
                                                onClick={() => {
                                                    toggleCustomActiveTab("5");
                                                }}
                                            >
                                                Business Card
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Col>
                                <Col sm={12} md={4} lg={4}>
                                    <div className="text-center text-md-end text-lg-end mb-2 mb-md-0 mb-lg-0">
                                        <img src={iconProfile} width={280} className="img-fluid" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <TabContent
                                        activeTab={customActiveTab}
                                    >
                                        <TabPane tabId="1">
                                            <ProfileGeneral dataGeneral={dataEmpGeneral} isEditGeneral={isEditGeneral} SaveDataEmpGeneral={SaveDataEmpGeneral} />
                                        </TabPane>
                                        {/* <TabPane tabId="2">
                                            <ProfilePersonal dataGeneral={dataEmpGeneral} dataFamily={dataEmpFamily} />
                                        </TabPane> */}
                                        <TabPane tabId="3">
                                            <ProfileWork dataWorkInfo={dataEmpWorkInfo} />
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <ProfileSignature dataEmpGeneral={dataEmpGeneral} />
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <ProfileBusinessCard dataEmpGeneral={dataEmpGeneral} />
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Profile;