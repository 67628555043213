import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledDropdown } from "reactstrap";
import axios from 'axios';
import ConfigData from '../../config.json';
import GridPhoneBook from "../../components/employee/grid_phonebook";
import Select from "react-select";
import ReactHtmlRenderer from 'react-html-renderer';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import avatar1 from "../../assets/images/user-dummy-img.jpg";
import iconPhoneBook from "../../assets/images/icon/img-phonebook.png";


const PhoneBook = () => {
    const [dataEmployees, setDataEmployees] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [selectShow, setSelectShow] = useState(null);
    const [ddlShow, setDdlShow] = useState([]);
    const [selectSort, setSelectSort] = useState(null);
    const [ddlSort, setDdlSort] = useState([]);
    const [txtSearch, setTxtSearch] = useState("");
    const [isShowModal, setIsShowModal] = useState();
    const [lightBoxIsOpen, setLightBoxIsOpen] = useState(false);
    const [lightBoxImage, setLightBoxImage] = useState("");
    const [empDetail, setEmpDetail] = useState(
        {
            id: 0,
            employee_id: "",
            title_th: "",
            title_en: "",
            name_th: "",
            name_en: "",
            surname_th: "",
            surname_en: "",
            nickname_th: "",
            nickname_en: "",
            phone_number: "",
            office_email: "",
            office_phone: "",
            name_business_unit: "",
            name_department: "",
            name_position: ""
        }
    );

    useEffect(() => {
        LoadDataGrid();
        LoadDataDepartment();
        setDdlSort([
            {label: "Name A-Z", value: "name_en-asc"}, 
            {label: "Name Z-A", value: "name_en-desc"}, 
            {label: "Department A-Z", value: "name_department-asc"}, 
            {label: "Department Z-A", value: "name_department-desc"}, 
            // {label: "Position A-Z", value: "name_position-asc"}, 
            // {label: "Position Z-A", value: "name_position-desc"}, 
        ]);
        setSelectSort({label: "Name A-Z", value: "name_en-asc"});
    }, []);

    const onClickDetail = (data) => {
        setEmpDetail(data)
        setIsShowModal(!isShowModal);
    };

    const onClickShowLightBox = (img) => {
        const objImg = (img !== "") ? ConfigData.IMAGE_URL + img : avatar1;
        setLightBoxImage(objImg);
        setLightBoxIsOpen(true);
    };

    const onClickCloseLightBox = () => {
        setLightBoxIsOpen(false);
    };

    const onSearch = (strSearch, strShow) => {
        let arrSearchData = dataEmployees;
        if(strShow !== null && strShow !== ""){
            arrSearchData = arrSearchData.filter((item) => {
                return item.id_department.toString() === strShow;
            });
        }

        if(strSearch !== null && strSearch !== ""){
            arrSearchData = arrSearchData.filter((item) => {
                let dataMap = item.name_en + " " + item.surname_en + " " + item.nickname_en + " " + item.office_email + " " + item.phone_number + " " + item.office_phone + " " + item.name_department;
                dataMap = dataMap.toLocaleLowerCase();
                return dataMap.includes(strSearch.toLocaleLowerCase());
            });
        }
        onSort(arrSearchData, (selectSort !== null) ? selectSort.value : "");
    };

    const onSort = (arrData, strSort) => {
        // console.log(arrData, strSort);
        if(strSort !== "" && strSort != null){
            let arrSort = strSort.split("-");
            let dataSort = SortData(arrData, arrSort[0], arrSort[1]);
            setGridData(dataSort);
        }else{
            let arrSort = ddlSort[0].value.split("-");
            let dataSort = SortData(arrData, arrSort[0], arrSort[1]);
            setSelectSort({label: "Name A-Z", value: "name_en-asc"});
            setGridData(dataSort);
        }
    };

    const SortData = (data, field, order) => {
        const sorted = [...data].sort((a, b) => {
            if (a[field] === null) return 1;
            if (b[field] === null) return -1;
            if (a[field] === null && b[field] === null) return 0;
            return (
                a[field].toString().localeCompare(
                    b[field].toString(), 
                    "en", 
                    {
                        numeric: true,
                    }
                ) * (order === "asc" ? 1 : -1)
            );
        });
        return sorted;
    }

    const LoadDataGrid = () => {
        let url = ConfigData.API_URL + "employee-general/grid?status=2";
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                setDataEmployees(respData.data);
                onSort(respData.data, "name_en-asc");
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    const LoadDataDepartment = () => {
        let url = ConfigData.API_URL + "department/ddl";
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                const arrData = respData.data.map((e) => {
                    return {
                        label: e.name,
                        value: e.id.toString(),
                    };
                });
                setDdlShow(arrData);
            }
        })
        .catch(error => {
            console.log(error);
            // handlerRespError(error, history);
        });
    };

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <Row className="flex-column-reverse flex-sm-row">
                                <Col sm={12} md={6} lg={6}>
                                    <h1 className="mb-2">Phone book</h1>
                                    <p className="mb-3">You can search all friend in here :)</p>
                                    <div className="form-icon right mb-2">
                                        <Input className="form-control" type="text" id="txtSerachTable" placeholder="Search" value={txtSearch || ""} onChange={(e) => { setTxtSearch(e.target.value); onSearch(e.target.value, (selectShow != null) ? selectShow.value : ""); }} />
                                        <i className="ri-search-2-line"></i>
                                    </div>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                    <div className="text-center text-md-end text-lg-end mb-2 mb-md-0 mb-lg-0">
                                        <img src={iconPhoneBook} width={220} className="img-fluid" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col sm={12} md={6} lg={4} className="mb-3">
                                                    <Label htmlFor="ddlShow" className="me-2">Show: </Label>
                                                    <div className="d-inline-block" style={{width: "80%"}}>
                                                        <Select id="ddlShow"
                                                            name="ddlShow"
                                                            value={selectShow}
                                                            onChange={(selected) => {
                                                                setSelectShow(selected);
                                                                onSearch(txtSearch, (selected != null) ? selected.value : "")
                                                            }}
                                                            options={ddlShow}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            placeholder="All Employee"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={6} lg={4} className="mb-3">
                                                    <Label htmlFor="ddlSort" className="me-2">Sort by: </Label>
                                                    <div className="d-inline-block" style={{width: "80%"}}>
                                                        {/* <Select id="ddlSort"
                                                            name="ddlSort"
                                                            value={selectSort}
                                                            onChange={(selected) => {
                                                                setSelectSort(selected);
                                                                onSort(gridData, (selected != null) ? selected.value : "");
                                                            }}
                                                            options={ddlSort}
                                                            isSearchable={true}
                                                        /> */}
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle tag="button" className="btn btn-profile-sort" id="dropdownMenuButton">
                                                                {(selectSort !== null) ? selectSort.label : "A-Z"} <i className="mdi mdi-chevron-down"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                {ddlSort.map((item, key) => (
                                                                    <DropdownItem key={"sort"+key} 
                                                                        onClick={(e) => {
                                                                            // console.log(item);
                                                                            setSelectSort(item);
                                                                            onSort(gridData, (item != null) ? item.value : "");
                                                                        }}>
                                                                        {item.label}
                                                                    </DropdownItem>
                                                                ))}
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <GridPhoneBook data={gridData} rowPerPage={40} onClickDetail={onClickDetail} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Modal Add and Edit */}
            <Modal id="modelPhoneBookDetail"
                // size="sm"
                isOpen={isShowModal}
                toggle={() => { setIsShowModal(!isShowModal); }}
                backdrop={'static'}
                centered
            >
                <ModalHeader id="modalBUHeader" 
                    className="modal-title"
                    toggle={() => { setIsShowModal(!isShowModal); }}
                >
                    
                </ModalHeader>
                <ModalBody>
                    <Row className="mb-3">
                        <Col md={12} className="text-center">
                            <div className="profile-user position-relative d-inline-block mx-auto mb-2">
                                <img className="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="125x125" width="125" src={(empDetail.employee_pic !== "") ? ConfigData.IMAGE_URL + empDetail.employee_pic : avatar1} onClick={() => onClickShowLightBox(empDetail.employee_pic)} />
                            </div>
                            {/* <img className="img-thumbnail img-emp-profile rounded-circle mb-2" alt="125x125" width="125" src={(empDetail.employee_pic !== "") ? ConfigData.IMAGE_URL + empDetail.employee_pic : avatar1} /> */}
                            <h5 className="mb-1">{empDetail.name_en + " " + empDetail.surname_en + ((empDetail.nickname_en === "") ? "" : " ("  + empDetail.nickname_en + ")")}</h5>
                            <p className="mb-1">{(empDetail.name_department === "") ? "-" : empDetail.name_department}</p>
                            <p className="mb-1" style={{color: "#0966F1"}}>{(empDetail.name_position === "") ? "-" : empDetail.name_position}</p>
                        </Col>
                    </Row>
                    <Row className="border-bottom my-2">
                        <Col md={12}>
                            <h5 className="mb-1" style={{color: "#0966F1"}}>About</h5>
                            <p className="mb-1">
                                <ReactHtmlRenderer html={(empDetail.about_me !== "") ? empDetail.about_me : "-"} />
                            </p>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col md={12}>
                            <p className="mb-1">
                                <i className="mdi mdi-email me-2"></i>
                                {(empDetail.office_email === "") ? "-" : empDetail.office_email}
                            </p>
                            <p className="mb-1">
                                <i className="mdi mdi-phone-message me-2"></i>
                                {(empDetail.office_phone === "") ? "-" : empDetail.office_phone}
                            </p>
                            <p className="mb-1">
                                <i className="mdi mdi-cellphone me-2"></i>
                                {(empDetail.phone_number === "") ? "-" : empDetail.phone_number}
                            </p>
                        </Col>
                    </Row>
                    {
                        (lightBoxIsOpen !== true) ? "" : <Lightbox image={lightBoxImage} onClose={onClickCloseLightBox} />
                    }
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default PhoneBook;