import { Redirect, Route, useLocation } from "react-router-dom";

const AuthProtected = (props) => {
    const location = useLocation();
    const token = (!sessionStorage.getItem("token-intranet")) ? null : sessionStorage.getItem("token-intranet");
    if(token === "" || token === null || typeof(token) === "undefined"){
        let pathName = location.pathname.replace("/", "");
        let arrPathName = pathName.split("/");
        pathName = (arrPathName.length > 1) ? arrPathName[0] : pathName;
        window.location.replace('https://btsg.net/intranet/login.php?self='+pathName);
        // return(
        //     <Redirect to={{ pathname: "/landing-page?rurl=" + pathName, state: { from: props.location } }} />
        // );
    }
    return <>{props.children}</>
};

const AccessRoute = ({ component: Component, ...rest }) => {
    return (
        <Route { ...rest }
            render={props => {
                return (<> <Component {...props} /> </>);
            }}
        />
    );
};

export { AuthProtected, AccessRoute }