const modelEmpGeneral = {
    id: 0,
    employee_id: "",
    title_th: "",
    title_en: "",
    name_th: "",
    name_en: "",
    surname_th: "",
    surname_en: "",
    nickname_th: "",
    nickname_en: "",
    gender: "",
    national_id: "",
    passport_id: "",
    birth_date: null,
    phone_number: "",
    shirt_size: "",
    employee_pic: "",
    address: "",
    bank_account: "",
    numb_of_children: "",
    work_premit_id: "",
    tax_id: "",
    sso_id: "",
    personal_email: "",
    marital_status: "",
    military_status: "",
    contact_name: "",
    contact_phone_number: "",
    contact_relation: "",
    about_me: "",
    skill_me: "",
    remark: "",
    status: "",
    name_shirt_size: "",
    id_business_unit: 0,
    id_department: 0,
    id_position: 0,
    office_email: "",
    office_phone: "",
    work_location: "",
    name_business_unit: "",
    name_department: "",
    name_position: "",
};

const modelEmpWorkInfo = {
    id: 0,
    id_employee_general: "",
    employee_id: "",
    id_business_unit: "",
    id_department: "",
    id_sub_department: "",
    id_division: "",
    id_sub_division: "",
    id_section: "",
    id_sub_section: "",
    id_position: "",
    id_employment_type: "",
    id_cost_center: "",
    email: "",
    supervisor: "",
    one_over_one: "",
    hod_id: "",
    mentor_id: "",
    join_date: null,
    terminaltion_date: "",
    probation_end_date: "",
    work_location: "",
    office_phone: "",
    remark: "",
    status: "",
    name_business_unit: "",
    name_department: "",
    name_sub_department: "",
    name_division: "",
    name_sub_division: "",
    name_section: "",
    name_sub_section: "",
    name_position: "",
    name_employment_type: "",
    name_cost_center: "",
    name_supervisor: "",
    name_one_over_one: "",
    name_hod: "",
    name_mentor: ""
};

const modelEmpWorkExp = {
    id: 0,
    id_employee_general: "",
    employee_id: "",
    company_name: "",
    begin_date: "",
    finish_date: "",
    work_detail: "",
    status: "1",
};

const modelEmpFamily = {
    id: 0,
    id_employee_general: "",
    employee_id: "",
    name: "",
    surname: "",
    address: "",
    phone_number: "",
    age: "",
    relationship: "",
    status: "1",
};

const modelEmpEductaion = {
    id: 0,
    id_employee_general: "",
    employee_id: "",
    id_institution: "",
    id_level_education: "",
    faculty: "",
    major: "",
    graduate_year: "",
    gpa: "",
    status: "1",
};

export { modelEmpGeneral, modelEmpWorkInfo, modelEmpWorkExp, modelEmpFamily, modelEmpEductaion}