import { Redirect } from 'react-router-dom';
import PhoneBook from '../pages/employee/phone_book';
import Profile from '../pages/employee/profile';
import Signature from '../pages/employee/signature';
import LandingPage from '../pages/employee/landing_page';
import Nuduans from '../pages/nuduan/nuduans';
import RedeemItem from '../pages/nuduan/redeem_item';


const authRoutes = [
    { path: "/phone-book", component: PhoneBook},
    { path: "/profile", component: Profile},
    { path: "/signature", component: Signature},
    { path: "/nuduan", component: Nuduans},
    { path: "/nuduan/:id", component: RedeemItem},
    {
        path: "/",
        expect: true,
        component: () => <Redirect to="/landing-page" />,
    }
];

const publicRoutes = [
    { path: "/landing-page", component: LandingPage}
];

export { publicRoutes, authRoutes };