import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import Select from "react-select";
import ReactHtmlRenderer from 'react-html-renderer';
import { useFormik } from "formik";
import * as Yup from "yup";
import { modelEmpGeneral } from './model_employee';
import ConfigData from '../../config.json';
import avatar1 from "../../assets/images/user-dummy-img.jpg";
import axios from 'axios';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { ConvertStringDate } from '../utils/convert-date';
import { ConvertEmployeeId } from '../utils/format-value';

const ProfileGeneral = ({dataGeneral, isEditGeneral, SaveDataEmpGeneral}) => {
    const [objDataGeneral, setObjDataGeneral] = useState(dataGeneral);
    const [dataDetailGeneral, setDataDetailGeneral] = useState(modelEmpGeneral);
    const [isShowModalGeneral, setIsShowModalGeneral] = useState(false);
    const [selectShirtSize, setSelectShirtSize] = useState(null);
    const [ddlShirtSize, setDdlShirtSize] = useState([]);
    const [lightBoxIsOpen, setLightBoxIsOpen] = useState(false);
    const [lightBoxImage, setLightBoxImage] = useState("");

    useEffect(() => {
        LoadDataDdlShirtSize();
    }, []);

    useEffect(() => {
        setObjDataGeneral(dataGeneral);
        setSelectShirtSize(ddlShirtSize.find(x => x.value === dataGeneral.shirt_size.toString()));
    }, [dataGeneral]);

    useEffect(() => {
        if(isEditGeneral === "false"){
            setIsShowModalGeneral(false);
        }
    }, [isEditGeneral]);

    const onClickEditGeneral = (data) => {
        let dataEdit = Object.assign({}, data);
        if(dataEdit.birth_date !== "" && dataEdit.birth_date !== null){
            dataEdit.birth_date = ConvertStringDate(dataEdit.birth_date, "dd-MM-yyyy", "yyyy-MM-dd", "-");
        }
        setDataDetailGeneral(dataEdit);
        setIsShowModalGeneral(true);
    };

    const onClickShowLightBox = (img) => {
        const objImg = (img !== "") ? ConfigData.IMAGE_URL + img : avatar1;
        setLightBoxImage(objImg);
        setLightBoxIsOpen(true);
    };

    const onClickCloseLightBox = () => {
        setLightBoxIsOpen(false);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: dataDetailGeneral.id || 0,
            name_en: dataDetailGeneral.name_en || "",
            surname_en: dataDetailGeneral.surname_en || "",
            nickname_en: dataDetailGeneral.nickname_en || "",
            employee_id: dataDetailGeneral.employee_id || "",
            birth_date: dataDetailGeneral.birth_date || "",
            shirt_size: dataDetailGeneral.shirt_size || "",
            phone_number: dataDetailGeneral.phone_number || "",
            employee_pic: dataDetailGeneral.employee_pic || "",
            about_me: dataDetailGeneral.about_me.replaceAll("<br/>", "\n") || "",
            skill_me: dataDetailGeneral.skill_me.replaceAll("<br/>", "\n") || "",
        },
        validationSchema: Yup.object({
            nickname_en: Yup.string().required("Please enter a Nickname"),
            // birth_date: Yup.string().required("Please enter a Birthday"),
            // phone_number: Yup.string().required("Please enter a Contact"),
        }),
        onSubmit: SaveDataEmpGeneral
    });

    const LoadDataDdlShirtSize = () => {
        let url = ConfigData.API_URL + "shirt-size/ddl";
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                const arrData = respData.data.map((e) => {
                    return {
                        label: e.name,
                        value: e.id.toString(),
                    };
                });
                setDdlShirtSize(arrData);
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    return (
        <React.Fragment>
            <Row>
                <Col xl={6}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xl={12}>
                                    <div className="justify-content-between d-flex mb-3">
                                        {/* <img className="img-thumbnail rounded-circle fl" alt="200x200" width="150" src={(objDataGeneral.employee_pic !== "") ? ConfigData.IMAGE_URL + objDataGeneral.employee_pic : avatar1} /> */}
                                        <div className="profile-user">
                                            {/* <img className="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="125x125" width="125" src={(objDataGeneral.employee_pic !== "") ? ConfigData.IMAGE_URL + objDataGeneral.employee_pic : avatar1} /> */}
                                            <img className="rounded-circle avatar-profile img-thumbnail user-profile-image" alt="125x125" width="125" src={(objDataGeneral.employee_pic !== "") ? ConfigData.IMAGE_URL + objDataGeneral.employee_pic : avatar1} onClick={() => onClickShowLightBox(objDataGeneral.employee_pic)} />
                                        </div>
                                        <Button className="btn-light btn-icon waves-effect" onClick={(e) => { onClickEditGeneral(objDataGeneral); }}>
                                            <i className=" ri-pencil-fill"></i>
                                        </Button>
                                    </div>
                                    <p className="profile-f-name mt-3 mb-0">{(objDataGeneral.name_en !== "") ? objDataGeneral.name_en + " " + objDataGeneral.surname_en : "-"}</p>
                                    <p className="profile-active"><i className="mdi mdi-circle-medium align-middle icon"></i>Active</p>
                                    <p className="profile-f-head" >Nickname</p>
                                    <p className="profile-f-detail">{(objDataGeneral.nickname_en !== "") ? objDataGeneral.nickname_en : "-"}</p>
                                    <p className="profile-f-head" >Employee ID.</p>
                                    <p className="profile-f-detail">{(objDataGeneral.employee_id !== "") ? ConvertEmployeeId(objDataGeneral.employee_id, 4) : "-"}</p>
                                    <p className="profile-f-head" >Mobile number</p>
                                    <p className="profile-f-detail">{(objDataGeneral.phone_number !== "") ? objDataGeneral.phone_number : "-"}</p>
                                    {/* <p className="profile-f-head" >Shirt size</p>
                                    <p className="profile-f-detail">{(objDataGeneral.name_shirt_size !== "") ? objDataGeneral.name_shirt_size : "-"}</p>
                                    <p className="profile-f-head" >Birthday</p>
                                    <p className="profile-f-detail">{(objDataGeneral.birth_date !== null) ? objDataGeneral.birth_date : "-"}</p> */}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {
                        (lightBoxIsOpen !== true) ? "" : <Lightbox image={lightBoxImage} onClose={onClickCloseLightBox} />
                    }
                </Col>
                <Col xl={6}>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <p className="profile-f-group">About</p>
                                    <div style={{height: 465}}>
                                        <p className="profile-f-detail" >
                                            <ReactHtmlRenderer html={(objDataGeneral.about_me !== "") ? objDataGeneral.about_me : "-"} />
                                        </p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <p className="profile-f-group">Skills</p>
                                    <div style={{height: 200}}>
                                        <p className="profile-f-detail">
                                            <ReactHtmlRenderer html={(objDataGeneral.skill_me !== "") ? objDataGeneral.skill_me : "-"} />
                                        </p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}
                </Col>
            </Row>
            {/* Modal Edit Profile General */}
            <Modal id="modalProfileGeneral"
                isOpen={isShowModalGeneral}
                toggle={() => { setIsShowModalGeneral(!isShowModalGeneral); }}
                backdrop={'static'}
                centered
            >
                <ModalHeader id="modalProfileGeneralHeader"
                    className="modal-title"
                    toggle={() => { setIsShowModalGeneral(!isShowModalGeneral); }}
                >
                    <p className="h5">Edit profile</p>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <Form className="needs-validation"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col md={12} className="text-center">
                                        {/* <div className="justify-content-center d-flex mb-3">
                                            <img className="img-thumbnail rounded-circle fl" alt="200x200" width="120" src={(validation.values.employee_pic !== "") ? ConfigData.IMAGE_URL + validation.values.employee_pic : avatar1} />
                                        </div> */}
                                        <div className="profile-user position-relative d-inline-block mx-auto mb-3">
                                            <img className="rounded-circle avatar-profile img-thumbnail user-profile-image" alt="125x125" width="125" src={(validation.values.employee_pic !== "") ? ConfigData.IMAGE_URL + validation.values.employee_pic : avatar1} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label className="text-muted">Name</Label>
                                            <p className="h5">{validation.values.name_en + " " + validation.values.surname_en}</p>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label className="text-muted" htmlFor="txtNickNameEN">Nickname</Label>
                                            <Input id="txtNickNameEN" 
                                                name="nickname_en"
                                                placeholder="Nickname"
                                                className="from-control"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.nickname_en || ""}
                                                invalid={ validation.touched.nickname_en && validation.errors.nickname_en ? true : false }    
                                            />
                                            { validation.touched.nickname_en && validation.errors.nickname_en ? 
                                                ( <FormFeedback type="invalid">{validation.errors.nickname_en}</FormFeedback> ) : false 
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label className="text-muted">Employee ID.</Label>
                                            <p className="h5">{ConvertEmployeeId(validation.values.employee_id, 4)}</p>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label className="text-muted" htmlFor="txtBirthDate">Birthday</Label>
                                            <Input id="txtBirthDate" 
                                                name="birth_date"
                                                placeholder="Birthday"
                                                type="date"
                                                className="from-control"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.birth_date || ""}
                                                invalid={ validation.touched.birth_date && validation.errors.birth_date ? true : false }
                                            />
                                            { validation.touched.birth_date && validation.errors.birth_date ? 
                                                ( <FormFeedback type="invalid">{validation.errors.birth_date}</FormFeedback> ) : false 
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label className="text-muted" htmlFor="ddlShirtSize">Shirt size</Label>
                                            <Select id="ddlShirtSize"
                                                name="shirt_size"
                                                value={selectShirtSize}
                                                onChange={(selected) => {
                                                    setSelectShirtSize(selected);
                                                    validation.handleChange('shirt_size')((selected !== null) ? selected.value : "");
                                                }}
                                                options={ddlShirtSize}
                                                isClearable={true}
                                                isSearchable={true}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label className="text-muted" htmlFor="txtPhoneNumber">Mobile number</Label>
                                            <Input id="txtPhoneNumber" 
                                                name="phone_number"
                                                placeholder="Mobile number"
                                                className="from-control"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.phone_number || ""}
                                                invalid={ validation.touched.phone_number && validation.errors.phone_number ? true : false }    
                                            />
                                            { validation.touched.phone_number && validation.errors.phone_number ? 
                                                ( <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback> ) : false 
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="txtAbout">About</Label>
                                            <Input id="txtAbout" 
                                                name="about_me"
                                                placeholder="About"
                                                type="textarea"
                                                maxLength={2500}
                                                className="from-control"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.about_me || ""}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col md="12">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="txtSkills">Skills</Label>
                                            <Input id="txtSkills" 
                                                name="skill_me"
                                                placeholder="Skills"
                                                type="textarea"
                                                className="from-control"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.skill_me || ""}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col md="12">
                                        <Button color="primary" type="button" onClick={() => setIsShowModalGeneral(false) } >
                                            Close
                                        </Button>
                                        <Button className="float-end" color="success" type="submit">
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default ProfileGeneral