import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as htmlToImage from 'html-to-image';
import SignatureTemplate from "../employee/signature_template";

const ProfileSignature = ({ dataEmpGeneral }) => {
    const [dataDevicePixelRatio, setDataDevicePixelRatio] = useState(1);
    const divTemplate1 = useRef(null);
    const divTemplate2 = useRef(null);
    const divTemplate3 = useRef(null);
    const divTemplate4 = useRef(null);
    const divTemplate5 = useRef(null);
    const divTemplate6 = useRef(null);
    const [bgTemplate1, setBgTemplate1] = useState(SignatureTemplate.BTSG_T1);
    const [bgTemplate2, setBgTemplate2] = useState(SignatureTemplate.BTSG_T2);
    const [bgTemplate3, setBgTemplate3] = useState(SignatureTemplate.BTSG_T3);
    const [bgTemplate4, setBgTemplate4] = useState(SignatureTemplate.BTSG_T4);
    const [bgTemplate5, setBgTemplate5] = useState(SignatureTemplate.BTSG_T5);
    const [bgTemplate6, setBgTemplate6] = useState(SignatureTemplate.BTSG_T6);
    const [objEmployee, setObjEmployee] = useState(dataEmpGeneral);

    useEffect(() => {
        setDataDevicePixelRatio((window.devicePixelRatio !== null && window.devicePixelRatio !== undefined) ? window.devicePixelRatio : 1);
    }, []);

    useEffect(() => {
        setObjEmployee(dataEmpGeneral);
        if(dataEmpGeneral.id_department === 7){
            setBgTemplate1(SignatureTemplate.MATCH_T1);
            setBgTemplate2(SignatureTemplate.MATCH_T2);
            setBgTemplate3(SignatureTemplate.MATCH_T3);
        }else if(dataEmpGeneral.id_department === 12){
            setBgTemplate1(SignatureTemplate.MOVE_T1);
            setBgTemplate2(SignatureTemplate.MOVE_T2);
            setBgTemplate3(SignatureTemplate.MOVE_T3);
        }else if(dataEmpGeneral.id_department === 18 || dataEmpGeneral.id_department === 19){
            setBgTemplate1(SignatureTemplate.MIX_T1);
            setBgTemplate2(SignatureTemplate.MIX_T2);
            setBgTemplate3(SignatureTemplate.MIX_T3);
        }else{
            setBgTemplate1(SignatureTemplate.BTSG_T1);
            setBgTemplate2(SignatureTemplate.BTSG_T2);
            setBgTemplate3(SignatureTemplate.BTSG_T3);
        }
        setBgTemplate4(SignatureTemplate.BTSG_T4);
        setBgTemplate5(SignatureTemplate.BTSG_T5);
        setBgTemplate6(SignatureTemplate.BTSG_T6);
    }, [dataEmpGeneral]);

    const onClickDownloadTemp1 = async () => {
        const dataUrl = await htmlToImage.toPng(divTemplate1.current, {pixelRatio: dataDevicePixelRatio});
        const link = document.createElement('a');
        link.download = "singature1.png";
        link.href = dataUrl;
        link.click();
    };

    const onClickDownloadTemp2 = async () => {
        const dataUrl = await htmlToImage.toPng(divTemplate2.current, {pixelRatio: dataDevicePixelRatio});
        const link = document.createElement('a');
        link.download = "singature2.png";
        link.href = dataUrl;
        link.click();
    };

    const onClickDownloadTemp3 = async () => {
        const dataUrl = await htmlToImage.toPng(divTemplate3.current, {pixelRatio: dataDevicePixelRatio});
        const link = document.createElement('a');
        link.download = "singature3.png";
        link.href = dataUrl;
        link.click();
    };

    const onClickDownloadTemp4 = async () => {
        const dataUrl = await htmlToImage.toPng(divTemplate4.current, {pixelRatio: dataDevicePixelRatio});
        const link = document.createElement('a');
        link.download = "singature4.png";
        link.href = dataUrl;
        link.click();
    };

    const onClickDownloadTemp5 = async () => {
        const dataUrl = await htmlToImage.toPng(divTemplate5.current, {pixelRatio: dataDevicePixelRatio});
        const link = document.createElement('a');
        link.download = "singature5.png";
        link.href = dataUrl;
        link.click();
    };

    const onClickDownloadTemp6 = async () => {
        const dataUrl = await htmlToImage.toPng(divTemplate6.current, {pixelRatio: dataDevicePixelRatio});
        const link = document.createElement('a');
        link.download = "singature6.png";
        link.href = dataUrl;
        link.click();
    };
    
    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <p className="profile-f-group">Signature</p>
                            <Row className="singnature">
                                <Col lg={12}>
                                    <div className="template1" 
                                        style={{backgroundImage: `url(${bgTemplate1})`}}
                                        ref={divTemplate1}
                                    >
                                        <p className="st-name">
                                            {objEmployee.name_en + " " + objEmployee.surname_en}
                                        </p>
                                        <p className="st-department">
                                            {objEmployee.name_department} Department
                                        </p>
                                        <p className="st-position">
                                            {objEmployee.name_position}
                                        </p>
                                        <p className="st-company">
                                            {objEmployee.name_business_unit}
                                        </p>
                                        <p className="st-address">
                                            {(objEmployee.work_location !== "") ? objEmployee.work_location : "14 Floor, TST Tower, 21 Viphavadi-Rangsit Rd., Chomphon, Chatuchak, Bangkok 10900"}
                                        </p>
                                        <p className="st-tel">
                                            <span className="st-topic">Tel.:</span> (+66) {(objEmployee.phone_number !== "") ? objEmployee.phone_number : objEmployee.office_phone}
                                            <span className="st-topic ps-2">Fax:</span> (+66) 02-273-8526
                                            <span className="st-topic ps-2">Email:</span> {objEmployee.office_email}
                                        </p>
                                        {/* <p className="st-fax">
                                            <span className="st-topic">Fax:</span> {objEmployee.fax}
                                        </p>
                                        <p className="st-email">
                                            <span className="st-topic">Email:</span> {objEmployee.email}
                                        </p> */}
                                    </div>
                                    <Button color="primary" type="button" className="btn-download-template mt-1" onClick={onClickDownloadTemp1}>
                                        Download
                                    </Button>
                                    <hr />
                                    <div className="template2" 
                                        style={{backgroundImage: `url(${bgTemplate2})`}}
                                        ref={divTemplate2}
                                    >
                                        <p className="st-name">
                                            {objEmployee.name_en + " " + objEmployee.surname_en}
                                        </p>
                                        <p className="st-department">
                                            {objEmployee.name_department} Department
                                        </p>
                                        <p className="st-position">
                                            {objEmployee.name_position}
                                        </p>
                                        <p className="st-company">
                                            {objEmployee.name_business_unit}
                                        </p>
                                        <p className="st-address">
                                            {(objEmployee.work_location !== "") ? objEmployee.work_location : "14 Floor, TST Tower, 21 Viphavadi-Rangsit Rd., Chomphon, Chatuchak, Bangkok 10900"}
                                        </p>
                                        <p className="st-tel">
                                            <span className="st-topic">Tel.:</span> (+66) {(objEmployee.phone_number !== "") ? objEmployee.phone_number : objEmployee.office_phone}
                                            <span className="st-topic ps-2">Fax:</span> (+66) 02-273-8526
                                            <span className="st-topic ps-2">Email:</span> {objEmployee.office_email}
                                        </p>
                                    </div>
                                    <Button color="primary" type="button" className="btn-download-template mt-1" onClick={onClickDownloadTemp2}>
                                        Download
                                    </Button>
                                    <hr />
                                    <div className="template3" 
                                        style={{backgroundImage: `url(${bgTemplate3})`}}
                                        ref={divTemplate3}
                                    >
                                        <p className="st-name">
                                            {objEmployee.name_en + " " + objEmployee.surname_en}
                                        </p>
                                        <p className="st-department">
                                            {objEmployee.name_department} Department
                                        </p>
                                        <p className="st-position">
                                            {objEmployee.name_position}
                                        </p>
                                        <p className="st-company">
                                            {objEmployee.name_business_unit}
                                        </p>
                                        <p className="st-address">
                                            {(objEmployee.work_location !== "") ? objEmployee.work_location : "14 Floor, TST Tower, 21 Viphavadi-Rangsit Rd., Chomphon, Chatuchak, Bangkok 10900"}
                                        </p>
                                        <p className="st-tel">
                                            <span className="st-topic">Tel.:</span> (+66) {(objEmployee.phone_number !== "") ? objEmployee.phone_number : objEmployee.office_phone}
                                            <span className="st-topic ps-2">Fax:</span> (+66) 02-273-8526
                                            <span className="st-topic ps-2">Email:</span> {objEmployee.office_email}
                                        </p>
                                    </div>
                                    <Button color="primary" type="button" className="btn-download-template mt-1" onClick={onClickDownloadTemp3}>
                                        Download
                                    </Button>
                                    <hr />
                                    <div className="template4" 
                                        style={{backgroundImage: `url(${bgTemplate4})`}}
                                        ref={divTemplate4}
                                    >
                                        <p className="st-name">
                                            {objEmployee.name_en + " " + objEmployee.surname_en}
                                        </p>
                                        <p className="st-department">
                                            {objEmployee.name_department} Department
                                        </p>
                                        <p className="st-position">
                                            {objEmployee.name_position}
                                        </p>
                                        <p className="st-company">
                                            {objEmployee.name_business_unit}
                                        </p>
                                        <p className="st-address">
                                            {(objEmployee.work_location !== "") ? objEmployee.work_location : "14 Floor, TST Tower, 21 Viphavadi-Rangsit Rd., Chomphon, Chatuchak, Bangkok 10900"}
                                        </p>
                                        <p className="st-tel">
                                            <span className="st-topic">Tel.:</span> (+66) {(objEmployee.phone_number !== "") ? objEmployee.phone_number : objEmployee.office_phone}
                                            <span className="st-topic ps-2">Fax:</span> (+66) 02-273-8526
                                            <span className="st-topic ps-2">Email:</span> {objEmployee.office_email}
                                        </p>
                                    </div>
                                    <Button color="primary" type="button" className="btn-download-template mt-1" onClick={onClickDownloadTemp4}>
                                        Download
                                    </Button>
                                    <hr />
                                    <div className="template5" 
                                        style={{backgroundImage: `url(${bgTemplate5})`}}
                                        ref={divTemplate5}
                                    >
                                        <p className="st-name">
                                            {objEmployee.name_en + " " + objEmployee.surname_en}
                                        </p>
                                        <p className="st-department">
                                            {objEmployee.name_department} Department
                                        </p>
                                        <p className="st-position">
                                            {objEmployee.name_position}
                                        </p>
                                        <p className="st-company">
                                            {objEmployee.name_business_unit}
                                        </p>
                                        <p className="st-address">
                                            {(objEmployee.work_location !== "") ? objEmployee.work_location : "14 Floor, TST Tower, 21 Viphavadi-Rangsit Rd., Chomphon, Chatuchak, Bangkok 10900"}
                                        </p>
                                        <p className="st-tel">
                                            <span className="st-topic">Tel.:</span> (+66) {(objEmployee.phone_number !== "") ? objEmployee.phone_number : objEmployee.office_phone}
                                            <span className="st-topic ps-2">Fax:</span> (+66) 02-273-8526
                                            <span className="st-topic ps-2">Email:</span> {objEmployee.office_email}
                                        </p>
                                    </div>
                                    <Button color="primary" type="button" className="btn-download-template mt-1" onClick={onClickDownloadTemp5}>
                                        Download
                                    </Button>
                                    <hr />
                                    <div className="template6" 
                                        style={{backgroundImage: `url(${bgTemplate6})`}}
                                        ref={divTemplate6}
                                    >
                                        <p className="st-name">
                                            {objEmployee.name_en + " " + objEmployee.surname_en}
                                        </p>
                                        <p className="st-department">
                                            {objEmployee.name_department} Department
                                        </p>
                                        <p className="st-position">
                                            {objEmployee.name_position}
                                        </p>
                                        <p className="st-company">
                                            {objEmployee.name_business_unit}
                                        </p>
                                        <p className="st-address">
                                            {(objEmployee.work_location !== "") ? objEmployee.work_location : "14 Floor, TST Tower, 21 Viphavadi-Rangsit Rd., Chomphon, Chatuchak, Bangkok 10900"}
                                        </p>
                                        <p className="st-tel">
                                            <span className="st-topic">Tel.:</span> (+66) {(objEmployee.phone_number !== "") ? objEmployee.phone_number : objEmployee.office_phone}
                                            <span className="st-topic ps-2">Fax:</span> (+66) 02-273-8526
                                            <span className="st-topic ps-2">Email:</span> {objEmployee.office_email}
                                        </p>
                                    </div>
                                    <Button color="primary" type="button" className="btn-download-template mt-1" onClick={onClickDownloadTemp6}>
                                        Download
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ProfileSignature;
