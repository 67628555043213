import BTSG_T1 from "../../assets/images/signature/BTSG-T1.png";
import BTSG_T2 from "../../assets/images/signature/BTSG-T2.png";
import BTSG_T3 from "../../assets/images/signature/BTSG-T3.png";
import MOVE_T1 from "../../assets/images/signature/MOVE-T1.png";
import MOVE_T2 from "../../assets/images/signature/MOVE-T2.png";
import MOVE_T3 from "../../assets/images/signature/MOVE-T2.png";
import MIX_T1 from "../../assets/images/signature/MIX-T1.png";
import MIX_T2 from "../../assets/images/signature/MIX-T2.png";
import MIX_T3 from "../../assets/images/signature/MIX-T3.png";
import MATCH_T1 from "../../assets/images/signature/MATCH-T1.png";
import MATCH_T2 from "../../assets/images/signature/MATCH-T2.png";
import MATCH_T3 from "../../assets/images/signature/MATCH-T3.png";
import BTSG_T4 from "../../assets/images/signature/BTSG-T4.png";
import BTSG_T5 from "../../assets/images/signature/BTSG-T5.png";
import BTSG_T6 from "../../assets/images/signature/BTSG-T6.png";

const SignatureTemplate = {
    BTSG_T1,
    BTSG_T2,
    BTSG_T3,
    BTSG_T4,
    BTSG_T5,
    BTSG_T6,
    MOVE_T1,
    MOVE_T2,
    MOVE_T3,
    MIX_T1,
    MIX_T2,
    MIX_T3,
    MATCH_T1,
    MATCH_T2,
    MATCH_T3,
};

export default SignatureTemplate;