import axios from "axios";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import configData from "../../config.json"

const LandingPage = () => {
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
    const empId = (params.get("ei") !== null) ? params.get("ei") : "";
    const redirectURL = (params.get("rurl") !== null) ? params.get("rurl") : "";
    sessionStorage.clear();
    useEffect(() => {
        if(empId != ""){
            let url = configData.API_URL_AUTHEN + "login-b2b";
            let data = {
                user_name: configData.APP_USERNAME,
                password: configData.APP_PASS,
                app_key: configData.APP_KEY,
                app_secret: configData.APP_SECRET
            };
            axios.post(url, data, { responseType: "json" })
            .then(resp => {
                let respData = resp.data;
                if(respData.is_sucess === true){
                    sessionStorage.setItem("token-intranet", respData.data.token);
                    sessionStorage.setItem("EmpId-intranet", empId);
                    sessionStorage.setItem("uid-intranet", empId);
                    if(redirectURL === "phone-book"){
                        history.push("/phone-book");
                    }else if(redirectURL === "profile"){
                        history.push("/profile");
                    }else if(redirectURL === "signature"){
                        history.push("/signature");
                    }else if(redirectURL === "nuduan"){
                        history.push("/nuduan");
                    }else{
                        history.push("/phone-book");
                        // window.location.replace('https://btsg.net/intranet/login');
                    }
                    
                }else{
                    console.log(respData);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }else{
            window.location.replace('https://btsg.net/intranet/login.php?self='+redirectURL);
        }
        
    }, []);

    return(
        <React.Fragment>
            <div></div>
        </React.Fragment>
    );
};

export default LandingPage;