import imgRedeemDone from "../../assets/images/icon/img-redeem-done.png";
import imgRedeemCoolDown from "../../assets/images/icon/img-redeem-cool-down.png";
import imgRedeemNotEnough from "../../assets/images/icon/img-redeem-not-enough.png";

const modelNuduanMyPoint = {
    EMPLOYEEID: "",
    HONOR_POINT: 0,
    NUDUAN_POINT: 0
};

const modelRedeemItem = {
    id: 0,
    code: "",
    item_type: "",
    point_type: "",
    name: "",
    description: "",
    point: "",
    item_pic: "",
    exchange_amount: 1,
    desc_condition: ""
};

const modelRedeemAlert = [
    {
        itemType: "1",
        sucess: [
            {
                type: "SUCESS",
                icon: imgRedeemDone,
                title: "Used <<point>> points for <<item_name>> redemption.",
                desc: "HR will proceed to notify you of the details via email."
            }
        ],
        error: [
            {
                type: "ERROR",
                icon: imgRedeemNotEnough,
                title: "Your accumulated score may not be sufficient.",
                desc: "keep trying! I'm here to encourage you."
            },
            {
                type: "ERROR_OUTOFSTOCK",
                icon: imgRedeemNotEnough,
                title: "The product is currently out of stock.",
                desc: "We apologize for any inconvenience."
            }
        ]
    },
    {
        itemType: "2",
        sucess: [
            {
                type: "SUCESS",
                icon: imgRedeemDone,
                title: "Used <<point>> points for <<item_name>> redemption.",
                desc: "HR will proceed to notify you of the details via email."
            }
        ],
        error: [
            {
                type: "ERROR",
                icon: imgRedeemNotEnough,
                title: "Your accumulated score may not be sufficient.",
                desc: "keep trying! I'm here to encourage you."
            },
            {
                type: "ERROR_TIME",
                icon: imgRedeemCoolDown,
                title: "You have already redeemed the privilege.",
                desc: "you can do it again once the deadline is met."
            }
        ]
    },
    {
        itemType: "3",
        sucess: [
            {
                type: "SUCESS",
                icon: imgRedeemDone,
                title: "Used <<point>> points for <<item_name>> redemption.",
                desc: "HR will proceed to notify you of the details via email."
            }
        ],
        error: [
            {
                type: "ERROR",
                icon: imgRedeemNotEnough,
                title: "Your accumulated score may not be sufficient.",
                desc: "keep trying! I'm here to encourage you."
            },
            {
                type: "ERROR_TIME",
                icon: imgRedeemCoolDown,
                title: "You have already redeemed the privilege.",
                desc: "you can do it again once the deadline is met."
            }
        ]
    }
];

export { modelNuduanMyPoint, modelRedeemItem, modelRedeemAlert };