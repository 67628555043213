import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MasterPage from '../layouts/master-page';
import { publicRoutes, authRoutes } from './route-name';
import { AccessRoute, AuthProtected } from './route-auth';

const RouteIndex = () => {
    const publicRoutesPaths = publicRoutes.map((r) => r.path);
    const authRoutesPaths = authRoutes.map((r) => r.path);
    return(
        <React.Fragment>
            <Switch>
                <Route path={publicRoutesPaths}>
                    <MasterPage>
                        <Switch>
                            {publicRoutes.map((route, idx) => (
                                <Route key={idx}
                                    path={route.path}
                                    component={route.component}
                                    exact={true}
                                />
                            ))}
                        </Switch>
                    </MasterPage>
                </Route>
                <Route path={authRoutesPaths}>
                    <AuthProtected>
                        <MasterPage>
                            <Switch>
                                {authRoutes.map((route, idx) => (
                                    <AccessRoute key={idx} 
                                        path={route.path}
                                        component={route.component}
                                        exact={true}   
                                    />
                                ))}
                            </Switch>
                        </MasterPage>
                    </AuthProtected>
                </Route>
            </Switch>
        </React.Fragment>
    );
};

export default RouteIndex;