import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import * as htmlToImage from 'html-to-image';
import iconPhoneBook from "../../assets/images/icon/img-phonebook.png";
import ConfigData from "../../config.json";
import axios from "axios";
import SignatureTemplate from "../../components/employee/signature_template";

const Signature = () => {
    const divTemplate1 = useRef(null);
    const divTemplate2 = useRef(null);
    const divTemplate3 = useRef(null);
    const [bgTemplate1, setBgTemplate1] = useState(SignatureTemplate.BTSG_T1);
    const [bgTemplate2, setBgTemplate2] = useState(SignatureTemplate.BTSG_T2);
    const [bgTemplate3, setBgTemplate3] = useState(SignatureTemplate.BTSG_T3);
    const [objEmployee, setObjEmployee] = useState({
        id: 0,
        name_en: "",
        surname_en: "",
        company_name: "",
        department_name: "",
        address: "",
        tel: "",
        fax: "",
        email: ""
    });
    
    useEffect(() => {
        //จัดการใช้ Template โดยดูจาก Department ซึ่ง Mix กับ Marketing Department จะอยู่ด้วยกัน นอกเหนือจาก match, move จะเป็น group
        const employeeId = (sessionStorage.getItem("EmpId-intranet") !== null) ? sessionStorage.getItem("EmpId-intranet") : 0;
        LoadDataEmployee(employeeId);
    }, []);

    const onClickDownloadTemp1 = async () => {
        const dataUrl = await htmlToImage.toPng(divTemplate1.current, {pixelRatio: 1});
        const link = document.createElement('a');
        link.download = "singature1.png";
        link.href = dataUrl;
        link.click();
    };

    const onClickDownloadTemp2 = async () => {
        const dataUrl = await htmlToImage.toPng(divTemplate2.current, {pixelRatio: 1});
        const link = document.createElement('a');
        link.download = "singature2.png";
        link.href = dataUrl;
        link.click();
    };

    const onClickDownloadTemp3 = async () => {
        const dataUrl = await htmlToImage.toPng(divTemplate3.current, {pixelRatio: 1});
        const link = document.createElement('a');
        link.download = "singature3.png";
        link.href = dataUrl;
        link.click();
    };

    const LoadDataEmployee = (employeeId) => {
        let url = new URL(ConfigData.API_URL + "employee-general/search");
        url.searchParams.set("status", 1);
        url.searchParams.set("empId", employeeId);
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setObjEmployee({
                    id: 0,
                    name_en: respData.data[0].name_en,
                    surname_en: respData.data[0].surname_en,
                    company_name: respData.data[0].name_business_unit,
                    department_name: respData.data[0].name_department,
                    address: respData.data[0].work_location || "14 Floor, TST Tower, 21 Viphavadi-Rangsit Rd., Chomphon, Chatuchak, Bangkok 10900",
                    tel: "(+66) " + (respData.data[0].phone_number !== "") ? respData.data[0].phone_number : respData.data[0].office_phone,
                    fax: "(+66) 02-273-8526",
                    email: respData.data[0].office_email
                });
                
                if(respData.data[0].id_department === 7){
                    setBgTemplate1(SignatureTemplate.MATCH_T1);
                    setBgTemplate2(SignatureTemplate.MATCH_T2);
                    setBgTemplate3(SignatureTemplate.MATCH_T3);
                }else if(respData.data[0].id_department === 12){
                    setBgTemplate1(SignatureTemplate.MOVE_T1);
                    setBgTemplate2(SignatureTemplate.MOVE_T2);
                    setBgTemplate3(SignatureTemplate.MOVE_T3);
                }else if(respData.data[0].id_department === 18 || respData.data[0].id_department === 19){
                    setBgTemplate1(SignatureTemplate.MIX_T1);
                    setBgTemplate2(SignatureTemplate.MIX_T2);
                    setBgTemplate3(SignatureTemplate.MIX_T3);
                }else{
                    setBgTemplate1(SignatureTemplate.BTSG_T1);
                    setBgTemplate2(SignatureTemplate.BTSG_T2);
                    setBgTemplate3(SignatureTemplate.BTSG_T3);
                }
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <Row className="flex-column-reverse flex-sm-row">
                                <Col sm={12} md={6} lg={6}>
                                    <h1 className="my-4">Signature</h1>
                                    <p className="mb-3">You can download template signature in here :)</p>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                    <div className="text-center text-md-end text-lg-end mb-2 mb-md-0 mb-lg-0">
                                        <img src={iconPhoneBook} width={205} className="img-fluid" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        {/* <CardBody className="d-flex justify-content-center"> */}
                                        <CardBody>
                                            <Row className="singnature">
                                                <Col lg={12}>
                                                    <div className="template1" 
                                                        style={{backgroundImage: `url(${bgTemplate1})`}}
                                                        ref={divTemplate1}
                                                    >
                                                        <p className="st-name">
                                                            {objEmployee.name_en + " " + objEmployee.surname_en}
                                                        </p>
                                                        <p className="st-department">
                                                            {objEmployee.department_name} Department
                                                        </p>
                                                        <p className="st-company">
                                                            {objEmployee.company_name}
                                                        </p>
                                                        <p className="st-address">
                                                            {objEmployee.address}
                                                        </p>
                                                        <p className="st-tel">
                                                            <span className="st-topic">Tel.:</span> {objEmployee.tel}
                                                            <span className="st-topic ps-2">Fax:</span> {objEmployee.fax}
                                                            <span className="st-topic ps-2">Email:</span> {objEmployee.email}
                                                        </p>
                                                        {/* <p className="st-fax">
                                                            <span className="st-topic">Fax:</span> {objEmployee.fax}
                                                        </p>
                                                        <p className="st-email">
                                                            <span className="st-topic">Email:</span> {objEmployee.email}
                                                        </p> */}
                                                    </div>
                                                    <Button color="primary" type="button" className="btn-download-template mt-1" onClick={onClickDownloadTemp1}>
                                                        Download
                                                    </Button>
                                                    <hr />
                                                    <div className="template2" 
                                                        style={{backgroundImage: `url(${bgTemplate2})`}}
                                                        ref={divTemplate2}
                                                    >
                                                        <p className="st-name">
                                                            {objEmployee.name_en + " " + objEmployee.surname_en}
                                                        </p>
                                                        <p className="st-department">
                                                            {objEmployee.department_name} Department
                                                        </p>
                                                        <p className="st-company">
                                                            {objEmployee.company_name}
                                                        </p>
                                                        <p className="st-address">
                                                            {objEmployee.address}
                                                        </p>
                                                        <p className="st-tel">
                                                            <span className="st-topic">Tel.:</span> {objEmployee.tel}
                                                            <span className="st-topic ps-2">Fax:</span> {objEmployee.fax}
                                                            <span className="st-topic ps-2">Email:</span> {objEmployee.email}
                                                        </p>
                                                    </div>
                                                    <Button color="primary" type="button" className="btn-download-template mt-1" onClick={onClickDownloadTemp2}>
                                                        Download
                                                    </Button>
                                                    <hr />
                                                    <div className="template3" 
                                                        style={{backgroundImage: `url(${bgTemplate3})`}}
                                                        ref={divTemplate3}
                                                    >
                                                        <p className="st-name">
                                                            {objEmployee.name_en + " " + objEmployee.surname_en}
                                                        </p>
                                                        <p className="st-department">
                                                            {objEmployee.department_name} Department
                                                        </p>
                                                        <p className="st-company">
                                                            {objEmployee.company_name}
                                                        </p>
                                                        <p className="st-address">
                                                            {objEmployee.address}
                                                        </p>
                                                        <p className="st-tel">
                                                            <span className="st-topic">Tel.:</span> {objEmployee.tel}
                                                            <span className="st-topic ps-2">Fax:</span> {objEmployee.fax}
                                                            <span className="st-topic ps-2">Email:</span> {objEmployee.email}
                                                        </p>
                                                    </div>
                                                    <Button color="primary" type="button" className="btn-download-template mt-1" onClick={onClickDownloadTemp3}>
                                                        Download
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Signature; 