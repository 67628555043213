import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

const ProfilePersonal = ({dataWorkInfo}) => {
    const [objDataWorkInfo, setObjDataWorkInfo] = useState(dataWorkInfo);

    useEffect(() => {
        setObjDataWorkInfo(dataWorkInfo);
    }, [dataWorkInfo]);

    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <p className="profile-f-group">Work information</p>
                            <Row>
                                <Col xl={6}>
                                    <p className="profile-f-head" >Current work</p>
                                    <p className="profile-f-detail">{(objDataWorkInfo.name_business_unit !== "") ? objDataWorkInfo.name_business_unit : "-"}</p>
                                    <p className="profile-f-head" >Department</p>
                                    <p className="profile-f-detail">{(objDataWorkInfo.name_department !== "") ? objDataWorkInfo.name_department : "-"}</p>
                                    <p className="profile-f-head" >Division</p>
                                    <p className="profile-f-detail">{(objDataWorkInfo.name_division !== "") ? objDataWorkInfo.name_division : "-"}</p>
                                    <p className="profile-f-head" >Position</p>
                                    <p className="profile-f-detail">{(objDataWorkInfo.name_position !== "") ? objDataWorkInfo.name_position : "-"}</p>
                                    <p className="profile-f-head" >Cost center</p>
                                    <p className="profile-f-detail">{(objDataWorkInfo.name_cost_center !== "") ? objDataWorkInfo.name_cost_center : "-"}</p>
                                    <p className="profile-f-head" >Mentor</p>
                                    <p className="profile-f-detail">{(objDataWorkInfo.name_mentor !== null) ? objDataWorkInfo.name_mentor : "-"}</p>
                                    <p className="profile-f-head" >Supervisor name</p>
                                    <p className="profile-f-detail">{(objDataWorkInfo.name_supervisor !== null) ? objDataWorkInfo.name_supervisor : "-"}</p>
                                </Col>
                                <Col xl={6}>
                                    <p className="profile-f-head" >One over one name</p>
                                    <p className="profile-f-detail">{(objDataWorkInfo.name_one_over_one !== null) ? objDataWorkInfo.name_one_over_one : "-"}</p>
                                    <p className="profile-f-head" >HOD name</p>
                                    <p className="profile-f-detail">{(objDataWorkInfo.name_hod !== null) ? objDataWorkInfo.name_hod : "-"}</p>
                                    <p className="profile-f-head" >Type of employment</p>
                                    <p className="profile-f-detail">{(objDataWorkInfo.name_employment_type !== "") ? objDataWorkInfo.name_employment_type : "-"}</p>
                                    <p className="profile-f-head" >Office phone</p>
                                    <p className="profile-f-detail">{(objDataWorkInfo.office_phone !== "") ? objDataWorkInfo.office_phone : "-"}</p>
                                    <p className="profile-f-head" >Work location</p>
                                    <p className="profile-f-detail">{(objDataWorkInfo.work_location !== "") ? objDataWorkInfo.work_location : "-"}</p>
                                    <p className="profile-f-head" >Join date</p>
                                    <p className="profile-f-detail">{(objDataWorkInfo.join_date !== null) ? objDataWorkInfo.join_date : "-"}</p>
                                    <p className="profile-f-head" >Contract end</p>
                                    <p className="profile-f-detail">{(objDataWorkInfo.terminaltion_date !== "") ? objDataWorkInfo.terminaltion_date : "-"}</p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ProfilePersonal