import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ConfigData from "../../config.json";
import avatar1 from "../../assets/images/user-dummy-img.jpg";
import honorGreen from "../../assets/images/icon/honor/green.jpg";
import honorSilver from "../../assets/images/icon/honor/silver.jpg";
import honorGold from "../../assets/images/icon/honor/gold.jpg";
import honorPlatinum from "../../assets/images/icon/honor/platinum.jpg";
import imgRedeemblankHistory from "../../assets/images/icon/img-redeem-history-blank.png";
import imgGiftItem from "../../assets/images/icon/img-gift-item.png";
import { ConvertEmployeeId, ConvertFormatNumber } from '../utils/format-value';
import { ConvertStringDate } from '../utils/convert-date';

const NuduanMyPoint = ({dataEmpGeneral, dataEmpWorkInfo, dataPoint, dataHistory}) => {
    const [objDataEmpGeneral, setObjDataEmpGeneral] = useState(dataEmpGeneral);
    const [objDataEmpWorkInfo, setObjDataEmpWorkInfo] = useState(dataEmpWorkInfo);
    const [objDataPoint, setObjDataPoint] = useState(dataPoint);
    const [arrDataHistory, setArrDataHistory] = useState(dataHistory);

    useEffect(() => {
        setObjDataEmpGeneral(dataEmpGeneral);
        setObjDataEmpWorkInfo(dataEmpWorkInfo);
        setObjDataPoint(dataPoint);
        setArrDataHistory(dataHistory);
    }, [dataEmpGeneral, dataEmpWorkInfo, dataPoint, dataHistory]);

    const getLevelHonorImg = (point) => {
        if(point >= 25000){
            return <img className="nuduan-honor-img" src={honorPlatinum} alt="" />;
        }else if(point >= 17000){
            return <img className="nuduan-honor-img" src={honorGold} alt="" />;
        }else if(point >= 11000){
            return <img className="nuduan-honor-img" src={honorSilver} alt="" />;
        }else if(point >= 6000){
            return <img className="nuduan-honor-img" src={honorGreen} alt="" />;
        }else{
            return null;
        }
    };

    const getLevelHonorText = (point) => {
        if(point >= 25000){
            return "Platinum card";
        }else if(point >= 17000){
            return "Gold card";
        }else if(point >= 11000){
            return "Silver card";
        }else if(point >= 6000){
            return "Green card";
        }else{
            return "";
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <Row className="nuduan-profile">
                                <Col lg={12}>
                                    <div className="justify-content-between d-flex mb-3">
                                        <div className="profile-user">
                                            <img className="rounded-circle avatar-profile img-thumbnail user-profile-image" alt="125x125" width="125" src={(objDataEmpGeneral.employee_pic !== "") ? ConfigData.IMAGE_URL + objDataEmpGeneral.employee_pic : avatar1} />
                                        </div>
                                        {getLevelHonorImg(dataPoint.HONOR_POINT)}
                                    </div>
                                    <p className="nuduan-emp-name mb-0">{(objDataEmpGeneral.name_en !== "") ? objDataEmpGeneral.name_en + " " + objDataEmpGeneral.surname_en : "-"}</p>
                                    <p className="nuduan-honor-text mb-4">{getLevelHonorText(dataPoint.HONOR_POINT)}</p>
                                    <p className="nuduan-head-topic mb-2">Employee ID.</p>
                                    <p className="nuduan-body-topic mb-4">{(objDataEmpGeneral.employee_id !== "") ? ConvertEmployeeId(objDataEmpGeneral.employee_id, 4) : "-"}</p>
                                    <Row>
                                        <Col lg={6}>
                                            <p className="nuduan-head-topic mb-2">Department</p>
                                            <p className="nuduan-body-topic mb-4">{(objDataEmpWorkInfo.name_department !== "") ? objDataEmpWorkInfo.name_department : "-"}</p>
                                        </Col>
                                        <Col lg={6}>
                                            <p className="nuduan-head-topic mb-2">Start Working</p>
                                            <p className="nuduan-body-topic mb-4">{(objDataEmpWorkInfo.join_date !== null) ? ConvertStringDate(objDataEmpWorkInfo.join_date, "dd-MM-yyyy", "dd/MM/yyyy", "-") : "-"}</p>
                                        </Col>
                                    </Row>
                                    <p className="nuduan-head-topic mb-2">Nuduan Points</p>
                                    <h1 className="nuduan-body-point mb-4">{(objDataPoint.NUDUAN_POINT !== "") ? ConvertFormatNumber(objDataPoint.NUDUAN_POINT) : "-"}</h1>
                                    <p className="nuduan-head-topic mb-2">Honor Points</p>
                                    <p className="nuduan-body-point mb-0">{(objDataPoint.HONOR_POINT !== "") ? ConvertFormatNumber(objDataPoint.HONOR_POINT) : "-"}</p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <Row className="nuduan-history">
                                <Col lg={12}>
                                    <p className="nuduan-head-topic mb-3">History</p>
                                    <Row className="nuduan-history-content">
                                        <Col lg={12}>
                                            {(arrDataHistory.length > 0) ? arrDataHistory.map((item, key) => (
                                                <Card className="nuduan-history-card" key={"row-history-" + key}>
                                                    <CardBody className="nuduan-history-card-body">
                                                        <Row className="align-items-center">
                                                            <Col sm={12} md={5} lg={5}>
                                                                <img className="nuduan-history-img" src={(item.item_pic !== "") ? ConfigData.IMAGE_NUDUAN_URL + "history/" + item.item_pic : imgGiftItem} alt="" />
                                                            </Col>
                                                            <Col sm={12} md={7} lg={7} className="ps-0 mt-2">
                                                                <p className="nuduan-history-iname mb-1">{(item.item_name !== "") ? item.item_name : "-"}</p>
                                                                <p className="nuduan-history-amount mb-2">{(item.redeem_amount !== "") ? item.redeem_amount : "0"} item</p>
                                                                <p className="nuduan-history-point mb-4">{(item.redeem_point !== "") ? "-" + ConvertFormatNumber(item.redeem_point) : "0"} Points</p>
                                                                <p className="nuduan-history-date mb-1">{(item.redeem_date !== null) ? ConvertStringDate(item.redeem_date, "dd-MM-yyyy", "dd/MM/yyyy", "-") : "-"}</p>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            )) : <Row className="nuduan-blank align-items-center">
                                                    <Col lg={12} className="text-center align-middle">
                                                        <img className="nuduan-blank-img mb-3" src={imgRedeemblankHistory} alt="" />
                                                        <p className="nuduan-blank-text">You haven't redeemed a reward yet.</p>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default NuduanMyPoint;