import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { modelEmpFamily } from './model_employee';

const ProfilePersonal = ({dataGeneral, dataFamily}) => {
    const [objDataGeneral, setObjDataGeneral] = useState(dataGeneral);
    const [arrDataFamily, setArrDataFamily] = useState(dataFamily);

    useEffect(() => {
        setObjDataGeneral(dataGeneral);
        if(dataFamily.length <= 0){
            dataFamily.push(modelEmpFamily);
        }
        setArrDataFamily(dataFamily);
    }, [dataGeneral, dataFamily]);

    return (
        <React.Fragment>
            <Row>
                <Col xl={6}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xl={12}>
                                    <p className="profile-f-group">Personal information</p>
                                    <p className="profile-f-head" >Identity number</p>
                                    <p className="profile-f-detail">{(objDataGeneral.national_id !== "") ? objDataGeneral.national_id : "-"}</p>
                                    <p className="profile-f-head" >SSO ID</p>
                                    <p className="profile-f-detail">{(objDataGeneral.sso_id !== "") ? objDataGeneral.sso_id : "-"}</p>
                                    <p className="profile-f-head" >Bank account</p>
                                    <p className="profile-f-detail">{(objDataGeneral.bank_account !== "") ? objDataGeneral.bank_account : "-"}</p>
                                    <p className="profile-f-head" >Provident Fund ID</p>
                                    <p className="profile-f-detail">-</p>
                                    <p className="profile-f-head" >Address</p>
                                    <p className="profile-f-detail">{(objDataGeneral.address !== "") ? objDataGeneral.address : "-"}</p>
                                    <p className="profile-f-head" >Current address</p>
                                    <p className="profile-f-detail">-</p>
                                    <p className="profile-f-head" >Personal e-mail</p>
                                    <p className="profile-f-detail">{(objDataGeneral.personal_email !== "") ? objDataGeneral.personal_email : "-"}</p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={6}>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <p className="profile-f-group">Family</p>
                                    {arrDataFamily.map((item, key) => (
                                        <div key={"family"+key}>
                                            <Row>
                                                <Col xl={12}>
                                                    <p className="profile-f-head" >Name</p>
                                                    <p className="profile-f-detail">{(item.name !== "") ? item.name : "-"}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl={6}>
                                                    <p className="profile-f-head" >Relation</p>
                                                    <p className="profile-f-detail">{(item.relationship !== "") ? item.relationship : "-"}</p>
                                                </Col>
                                                <Col xl={6}>
                                                    <p className="profile-f-head" >Phone</p>
                                                    <p className="profile-f-detail">{(item.phone_number !== "") ? item.phone_number : "-"}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                   
                                    <hr />
                                    <p className="profile-f-group">Emergency contact</p>
                                    <p className="profile-f-head" >Name</p>
                                    <p className="profile-f-detail">{(objDataGeneral.contact_name !== "") ? objDataGeneral.contact_name : "-"}</p>
                                    <p className="profile-f-head" >Phone</p>
                                    <p className="profile-f-detail">{(objDataGeneral.contact_phone_number !== "") ? objDataGeneral.contact_phone_number : "-"}</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ProfilePersonal