import React, { useEffect, useState } from 'react';
import { useParams, useHistory} from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
import ReactHtmlRenderer from 'react-html-renderer';
import axios from 'axios';
import Swal from 'sweetalert2';
import ConfigData from "../../config.json";
import imgGiftItem from "../../assets/images/icon/img-gift-item.png";

import { modelNuduanMyPoint, modelRedeemAlert, modelRedeemItem } from '../../components/nuduan/model_nuduan';
import { ConvertFormatNumber } from '../../components/utils/format-value';

const RedeemItem = () => {
    const { id } = useParams();
    const history = useHistory();
    const [amountItem, setAmountItem] = useState(1);
    const [minItemExchange, setMinItemExchange] = useState(0);
    const [maxItemExchange, setMaxItemExchange] = useState(0);
    const [amountPoint, setAmountPoint] = useState(0);
    const [canRedeem, setCanRedeem] = useState(false);
    const [dataMyPoint, setDataMyPoint] = useState(modelNuduanMyPoint);
    const [dataItem, setDataItem] = useState(modelRedeemItem);

    useEffect(() => {
        const employeeId = (sessionStorage.getItem("EmpId-intranet") !== null) ? sessionStorage.getItem("EmpId-intranet") : 0;
        let itemId = 0;
        let itemType = 0;
        try{
            let paramDecode = atob(id);
            let arrParam = paramDecode.split(";");
            if(arrParam.length > 0){
                itemId = arrParam[0];
                itemType = arrParam[1];
            }
        }catch (error){
            itemId = 0;
            itemType = 0;
        }
        LoadDataItem(itemId);
        LoadDataMyPoint(employeeId);
        CheckCanRedeem(employeeId, itemId, itemType);
    }, [id]);

    const getItemTypeName = () => {
        if(dataItem.item_type === "1"){
            return "Nuduan Reward";
        }else if(dataItem.item_type === "2"){
            return "Cash Reward";
        }else if(dataItem.item_type === "3"){
            return "Scholarship Reward";
        }else{
            return "-"
        }
    };

    const getMyPoint = (isBase) => {
        if(isBase === true){
            if(dataItem.point_type === "1"){
                return dataMyPoint.NUDUAN_POINT;
            }else if(dataItem.point_type === "2"){
                return dataMyPoint.HONOR_POINT;
            }else{
                return 0;
            }
        }else{
            if(dataItem.point_type === "1"){
                return (dataMyPoint.NUDUAN_POINT !== "") ? ConvertFormatNumber(dataMyPoint.NUDUAN_POINT) : "-";
            }else if(dataItem.point_type === "2"){
                return (dataMyPoint.HONOR_POINT !== "") ? ConvertFormatNumber(dataMyPoint.HONOR_POINT) : "-";
            }else{
                return "-";
            }
        }
        
    };

    const setHtmlAlert = (strStatus, itemType, redeemPoint, myPoint, itemName) => {
        const redeemAlert = modelRedeemAlert.find(obj => obj.itemType === itemType);
        let alertDetail = null;
        if(strStatus === "SUCESS"){
            alertDetail = redeemAlert.sucess.find(obj => obj.type === strStatus);
        }else{
            alertDetail = redeemAlert.error.find(obj => obj.type === strStatus);
        }
        
        let strHtml = "<div class='nuduan-alert'>";
        strHtml += " <img class='nuduan-alert-img' src='" + alertDetail.icon + "' />";
        strHtml += " <p class='nuduan-alert-title'>" + alertDetail.title.replace("<<point>>", ConvertFormatNumber(redeemPoint)).replace("<<item_name>>", itemName) + "</p>";
        strHtml += " <p class='nuduan-alert-desc'>" + alertDetail.desc + "</p>";
        if(itemType !== "3"){
            strHtml += (strStatus === "SUCESS") ? " <p class='nuduan-alert-label'>Remaining points</p>" : "";
            strHtml += (strStatus === "SUCESS") ? " <p class='nuduan-alert-point'>" + ConvertFormatNumber(myPoint - redeemPoint) + "</p>" : "";
        }
        strHtml += "</div>";

        return strHtml;
    };

    const OnClickBack = () => {
        history.push("/nuduan");
    };

    const OnClickInputSpin = (status) => {
        let amount = amountItem;
        if(status === "UP"){
            amount = amountItem + 1;
        }else{
            amount = amountItem - 1;
        }

        if(maxItemExchange > 0){
            if(amount < 1){
                amount = 1;
            }
    
            if(amount > maxItemExchange){
                amount = maxItemExchange;
            }
        }else{
            amount = 0;
        }
        
        setAmountItem(amount);
        setAmountPoint((amount > 0) ? dataItem.point * amount : dataItem.point);
    }

    const OnCLickRedeem = () => {
        Swal.fire({
            title: 'Do you wish to redeem an ' + dataItem.name + ' ?',
            // text: 'Do you want to redeem this ' + dataItem.name + ' ?',
            showCancelButton: true,
            confirmButtonText: 'Redeem',
            confirmButtonColor: '#00599B',
        }).then((result) => {
            if(result.isConfirmed){
                let myPoint = (dataItem.point_type === "1") ? dataMyPoint.NUDUAN_POINT : dataMyPoint.HONOR_POINT;
                if(parseInt(myPoint) >= parseInt(amountPoint) && amountItem > 0){
                    SetRedeeemItems();
                }else{
                    Swal.fire({
                        html: setHtmlAlert("ERROR", dataItem.item_type, amountPoint, getMyPoint(true), dataItem.name),
                        confirmButtonColor: '#00599B',
                    });
                }
            }
        });
        
    };

    const LoadDataMyPoint = (employeeId) => {
        let url = new URL(ConfigData.API_URL_NUDUAN + "get-my-point.php");
        url.searchParams.set("empId", employeeId);
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setDataMyPoint(respData.data[0]);
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    const LoadDataItem = (id) => {
        let url = new URL(ConfigData.API_URL_NUDUAN + "get-item-by-id.php");
        url.searchParams.set("id", id);
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true && respData.data.length > 0){
                setDataItem(respData.data[0]);
                setAmountPoint(respData.data[0].point);
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    const CheckCanRedeem = (empId, itemId, itemType) => {
        let url = new URL(ConfigData.API_URL_NUDUAN + "get-can-redeem.php");
        url.searchParams.set("empId", empId);
        url.searchParams.set("itemId", itemId);
        url.searchParams.set("itemType", itemType);
        let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { headers: { 
            'Authorization' : token ,
        }, responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                setCanRedeem(true);
            }else{
                setCanRedeem(false);
            }

            if(parseInt(respData.data) > 0){
                setAmountItem(1);
                setMinItemExchange(1);
                setMaxItemExchange(parseInt(respData.data));
            }else{
                setAmountItem(0);
                setMinItemExchange(0);
                setMaxItemExchange(0);
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    const SetRedeeemItems = () => {
        let url = ConfigData.API_URL_NUDUAN + "redeem-item.php";
        // let token = "Bearer " + sessionStorage.getItem("token-intranet");
        let empId = (sessionStorage.getItem("EmpId-intranet") !== null) ? sessionStorage.getItem("EmpId-intranet") : 0;
        const data = new FormData();
        data.append("itemId", dataItem.id);
        data.append("empId", empId);
        data.append("amount", amountItem);
        data.append("point", amountPoint);
        data.append("itemType", dataItem.item_type);
        axios.post(url, data, { responseType: "json" })
        .then(resp => {
            let respData = resp.data;
            if(respData.is_sucess === true){
                SendEmailRedeeem(respData.data);
                Swal.fire({
                    html: setHtmlAlert("SUCESS", dataItem.item_type, amountPoint, getMyPoint(true), dataItem.name),
                    confirmButtonColor: '#00599B',
                });
                LoadDataMyPoint(empId);
                CheckCanRedeem(empId, dataItem.id, dataItem.item_type);
            }else{
                Swal.fire({
                    html: setHtmlAlert(respData.data, dataItem.item_type, amountPoint, getMyPoint(true), dataItem.name),
                    confirmButtonColor: '#00599B',
                });
            }
        })
        .catch(error => {
            // handlerRespError(error, history);
            Swal.fire({
                html: setHtmlAlert("ERROR", dataItem.item_type, amountPoint, getMyPoint(true), dataItem.name),
                confirmButtonColor: '#00599B',
            });
        });
    };

    const SendEmailRedeeem = (redeemId) => {
        let url = new URL(ConfigData.API_URL_NUDUAN + "send-redeem.php");
        url.searchParams.set("redeemId", redeemId);
        // let token = "Bearer " + sessionStorage.getItem("token-intranet");
        axios.get(url, { responseType: "json" })
        .then(resp => {
            // console.log(resp.data);
        })
        .catch(error => {
            console.log(error);
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="nuduan-redeem-desc">
                        <Col lg={12}>
                            <div className="mb-3 ">
                                <span onClick={(e) => { OnClickBack(); }}>
                                    <i className="ri-arrow-left-line nuduan-rd-back"></i>
                                </span>
                                <p className="nuduan-rd-title">Redeem point</p>
                            </div>
                            
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={12}>
                                            <p className="nuduan-rd-itemtype">{getItemTypeName()}</p>
                                            <Row className="justify-content-center">
                                                <Col sm={12} md={5} lg={5}>
                                                    {(dataItem.item_pic !== "") ? (
                                                        <img className="nuduan-rd-img" src={(dataItem.item_pic !== "") ? ConfigData.IMAGE_NUDUAN_URL + "page/" + dataItem.item_pic : imgGiftItem} alt="" />
                                                    ) : null}
                                                    <p className="nuduan-rd-name mb-0 mt-2">{(dataItem.name !== "") ? dataItem.name : "-"}</p>
                                                    <p className="nuduan-rd-desc mb-4">{(dataItem.description !== "") ? dataItem.description : ""}</p>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <p className="nuduan-rd-label-point text-start mb-3">Your Points : </p>
                                                            <p className="nuduan-rd-value-point text-end mb-3">{getMyPoint(false)} Points</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <p className="nuduan-rd-label-point text-start mb-3">Redeem For : </p>
                                                            <p className="nuduan-rd-value-point text-end mb-3">{(amountPoint !== "") ? ConvertFormatNumber(amountPoint) : "-"} Points</p>
                                                        </Col>
                                                    </Row>
                                                    {(dataItem.item_type === "1") ? (
                                                        <Row >
                                                            <Col lg={12} className="text-end">
                                                                <div className="input-step nuduan-rd-ips">
                                                                    <button
                                                                        type="button"
                                                                        className="minus nuduan-rd-button"
                                                                        onClick={() => {
                                                                            OnClickInputSpin("DOWN");
                                                                        }}
                                                                    >
                                                                        <i className="ri-subtract-fill"></i> 
                                                                    </button>
                                                                    <Input type="number"
                                                                        className="nuduan-rd-input"
                                                                        value={amountItem}
                                                                        min={minItemExchange}
                                                                        max={maxItemExchange}
                                                                        readOnly
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        className="plus nuduan-rd-button"
                                                                        onClick={() => {
                                                                            OnClickInputSpin("UP");
                                                                        }}
                                                                    >
                                                                        <i className="ri-add-fill"></i> 
                                                                    </button>
                                                                </div>
                                                                <p className="nuduan-rd-msgmax">*Maximum {maxItemExchange} items</p>
                                                            </Col>
                                                        </Row>
                                                    ) : null}
                                                    <Row>
                                                        <Col lg={12}>
                                                            <p className="nuduan-rd-con-label mb-1">Terms & Conditions :</p>
                                                            <p className="nuduan-rd-con-desc">
                                                                <ReactHtmlRenderer html={(dataItem.desc_condition !== "") ? dataItem.desc_condition : "-"} />
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col sm={12} md={7} lg={7}>
                                                            <Button className="nuduan-rd-btn"
                                                                type="button"
                                                                disabled={(canRedeem) ? false : true}
                                                                onClick={() => { OnCLickRedeem(); }}
                                                            >
                                                                Redeem
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default RedeemItem;