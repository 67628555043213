import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ConfigData from "../../config.json";
import imgGiftItem from "../../assets/images/icon/img-gift-item.png";

const NuduanRedeemPoint = ({itemProducts, itemMoneys, onClickGoToRedeem}) => {
    const [arrItemProduct, setArrItemProduct] = useState(itemProducts);
    const [arrItemMoney, setArrItemMoney] = useState(itemMoneys);

    useEffect(() => {
        setArrItemProduct(itemProducts);
        setArrItemMoney(itemMoneys);
    }, [itemProducts, itemMoneys]);

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card className="nuduan-redeem">
                        <CardBody>
                            <Row>
                                <Col lg={12}>
                                    <p className="nuduan-redeem-title">Nuduan Rewards</p>
                                    <Row>
                                        {(arrItemProduct.length > 0) ? arrItemProduct.map((item, key) => (
                                            <Col sm={12} md={3} lg={3} className="nuduan-item-detail" key={"nuduan-product-" + key}>
                                                <Card className="nuduan-item-card">
                                                    <CardBody>
                                                        <Row className="nuduan-product" onClick={(e) => { onClickGoToRedeem(item.id, item.item_type); }}>
                                                            <Col lg={12}>
                                                                <img className="nuduan-product-img" src={(item.item_pic !== "") ? ConfigData.IMAGE_NUDUAN_URL + "list/" + item.item_pic : imgGiftItem} alt="" />
                                                                <p className="nuduan-product-titel mt-2 mb-0">{(item.name !== "") ? item.name : "-"}</p>
                                                                <p className="nuduan-product-desc mb-0">{(item.description !== "") ? item.description : ""}</p>
                                                                <p className="nuduan-product-out-of-stock mb-3">{(parseInt(item.in_stock) <= 0 && item.item_type === "1") ? "Out of stock" : <br/>}</p>
                                                                <p className="nuduan-product-point mt-2 mb-1">{(item.point !== "") ? item.point : "-"} Points</p>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )) : (
                                            <Col lg={12} style={{width: "100%"}} >
                                                <Row className="align-item-center">
                                                    <Col lg={12} className="text-center">
                                                        <h4 className="my-4">No Result Found!</h4>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <p className="nuduan-redeem-title">Cash Rewards</p>
                                    <Row>
                                        {(arrItemMoney.length > 0) ? arrItemMoney.map((item, key) => (
                                            <Col sm={12} md={3} lg={3} className="nuduan-item-detail" key={"nuduan-money-" + key}>
                                                <Row className="nuduan-money" onClick={(e) => { onClickGoToRedeem(item.id, item.item_type); }}>
                                                    <Col lg={12}>
                                                        <img className="nuduan-money-img" src={(item.item_pic !== "") ? ConfigData.IMAGE_NUDUAN_URL + "list/" + item.item_pic : imgGiftItem} alt="" />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )) : (
                                            <Col lg={12} style={{width: "100%"}} >
                                                <Row className="align-item-center">
                                                    <Col lg={12} className="text-center">
                                                        <h4 className="my-4">No Result Found!</h4>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default NuduanRedeemPoint;